<div class="search-container">
    <div class="search-results">
        <div class="search-scroll">
            <div class="row"
                *ngIf="suggestionData?.country || suggestionData?.category || suggestionData?.destination || suggestionData?.product">
                <div class="col-md-12">
                    <div class="ul mb-0">
                        <div class="li description_16 mb-2" *ngFor="let country of suggestionData?.country">
                            <a href="{{searchCountry(country?.destinations_Country_CountryName)}}" class="pointer">
                                <div class="d-flex align-items-center pointer">
                                    <div class="suggestion_img">
                                        <img src="./assets/icons/country_icon.webp" alt="">
                                    </div>
                                    <div class="text-capitalize">
                                        {{country?.destinations_Country_CountryName}}
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="li description_16 mb-2" *ngFor="let category of suggestionData?.category">
                            <a href="{{searchCategory(category?.categories_CategoryName)}}" class="pointer">
                                <div class="d-flex align-items-center pointer">
                                    <div class="suggestion_img">
                                        <img src="./assets/icons/category_icon.webp" alt="">
                                    </div>
                                    <div class="text-capitalize">
                                        {{category?.categories_CategoryName}}
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="li description_16 mb-2" *ngFor="let destination of suggestionData?.destination">
                            <a href="{{searchDestination(destination?.destinations_DestinationName)}}" (click)="destinationLog(destination?.destinations_DestinationName)" class="pointer">
                                <div class="d-flex align-items-center pointer">
                                    <div class="suggestion_img">
                                        <img src="./assets/icons/map_icon.webp" alt="">
                                    </div>
                                    <div class="text-capitalize">
                                        {{destination?.destinations_DestinationName}}
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="li description_16 mb-2" *ngFor="let product of suggestionData?.product">
                            <a class="pointer" href="{{openLink(product?.slug)}}" target="_blank">
                                <div class="d-flex align-items-center pointer">
                                    <div class="suggestion_img">
                                        <img src="./assets/icons/product_icon.webp" alt="">
                                    </div>
                                    <div class="text-capitalize">
                                        {{product?.name}}
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="text-danger heading_16"
                        *ngIf="suggestionData?.country?.length === 0 && suggestionData?.category?.length === 0 && suggestionData?.destination?.length === 0 && suggestionData?.product?.length === 0">
                        No Results Found
                    </div>
                </div>
            </div>
            <div class="row"
                *ngIf="!suggestionData?.country && !suggestionData?.category && !suggestionData?.destination && !suggestionData?.product">
                <!-- <div class="col-md-12">
                    <div class=" d-flex justify-content-between">
                        <div class="heading_16 mb-3">
                            Search history
                        </div>
                        <div class="delete_icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                fill="none">
                                <path
                                    d="M5.5 5.5C5.77614 5.5 6 5.72386 6 6V12C6 12.2761 5.77614 12.5 5.5 12.5C5.22386 12.5 5 12.2761 5 12V6C5 5.72386 5.22386 5.5 5.5 5.5Z"
                                    fill="black" />
                                <path
                                    d="M8 5.5C8.27614 5.5 8.5 5.72386 8.5 6V12C8.5 12.2761 8.27614 12.5 8 12.5C7.72386 12.5 7.5 12.2761 7.5 12V6C7.5 5.72386 7.72386 5.5 8 5.5Z"
                                    fill="black" />
                                <path
                                    d="M11 6C11 5.72386 10.7761 5.5 10.5 5.5C10.2239 5.5 10 5.72386 10 6V12C10 12.2761 10.2239 12.5 10.5 12.5C10.7761 12.5 11 12.2761 11 12V6Z"
                                    fill="black" />
                                <path
                                    d="M14.5 3C14.5 3.55228 14.0523 4 13.5 4H13V13C13 14.1046 12.1046 15 11 15H5C3.89543 15 3 14.1046 3 13V4H2.5C1.94772 4 1.5 3.55228 1.5 3V2C1.5 1.44772 1.94772 1 2.5 1H6C6 0.447715 6.44772 0 7 0H9C9.55229 0 10 0.447715 10 1H13.5C14.0523 1 14.5 1.44772 14.5 2V3ZM4.11803 4L4 4.05902V13C4 13.5523 4.44772 14 5 14H11C11.5523 14 12 13.5523 12 13V4.05902L11.882 4H4.11803ZM2.5 3H13.5V2H2.5V3Z"
                                    fill="black" />
                            </svg>
                        </div>
                    </div>
                    <div>
                        <div class="search-label description_16 mb-3">
                            Singapore
                        </div>
                        <div class="search-label description_16 mb-3">
                            Dubai
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="heading_16 mb-3">
                        Most popular
                    </div>
                    <div class="search-label description_16 mb-3">
                        Singapore
                    </div>
                    <div class="search-label description_16 mb-3">
                        Dubai
                    </div>
                </div> -->
                <div class="col-md-6">
                    <div class="search-result-box">
                        <div class="heading_16 mb-3">
                            Bestseller
                        </div>
                        <div class="ul">
                            <div class="li description_16 mb-2" *ngFor="let dest of searchSuggestionListData?.product">
                                <a href="{{openLink(dest?.slug)}}" target="_blank" class="pointer">
                                    <div class="d-flex align-items-center pointer">
                                        <div class="suggestion_img">
                                            <img src="./assets/icons/product_icon.svg" alt="">
                                        </div>
                                        <div class="text-capitalize">
                                            {{dest?.name}}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="search-result-box">
                        <div class="heading_16 mb-3">
                            Trending Destinations
                        </div>
                        <div class="ul">
                            <div class="li description_16 mb-2"
                                *ngFor="let dest of searchSuggestionListData?.destination">
                                <a href="{{searchDestination(dest?.destinationName)}}" class="pointer">
                                    <div class="d-flex align-items-center pointer">
                                        <div class="suggestion_img">
                                            <img src="./assets/icons/map_icon.svg" alt="">
                                        </div>
                                        <div class="text-capitalize">
                                            {{dest?.destinationName}}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>