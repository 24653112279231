import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'trpx-product-review',
  templateUrl: './product-review.component.html',
  styleUrls: ['./product-review.component.scss']
})
export class ProductReviewComponent implements OnInit {

  @Input() reviewDetails: any = [];

  constructor() {
  }
  ngOnInit(): void {
  }
}
