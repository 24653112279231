import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'trpx-destination-single-carousels',
  templateUrl: './destination-single-carousels.component.html',
  styleUrls: ['./destination-single-carousels.component.scss']
})
export class DestinationSingleCarouselsComponent {
  @Input() slides: any;
  @Input() componentName: any;


  constructor(public router: Router) {

  }
  ngOnInit(): void { }

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };

  sliderOutClick(data: any) {
    let urlToNavigate = data?.redirectionValue;
    if (data?.redirectionType == "URL_REDIRECTION") {
      window.location.href = urlToNavigate;
    }
    if (data?.redirectionType == "SEARCH_RESULTS") {
      this.router.navigate(['/search-results'], { queryParams: { type: 'experiences', experiance: urlToNavigate } });
    }
  }
}
