import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/service/product.service';

@Component({
  selector: 'trpx-group-booking',
  templateUrl: './group-booking.component.html',
  styleUrls: ['./group-booking.component.scss']
})
export class GroupBookingComponent implements OnInit {
  isLoading: boolean = false;
  groupBookModalRef?: BsModalRef;

  groupBookingForms: any = FormGroup;
  countryCode: string = '+91';
  countryData: any;

  formData: any;
  isDisabled: boolean = false;

  @Input() ticketData: any;
  @Input() isEnquiry: any;

  @Output("closeModal") closeModal: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, public router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public productService: ProductService,
    private modalService: BsModalService, private cdr: ChangeDetectorRef,) {
    this.getData().subscribe((response: any) => {
      this.countryData = response;
      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {
    const data = localStorage.getItem('customer_profile');
    if (data) {
      this.formData = JSON.parse(data);
    }
    this.resetForm();
  }

  getData() {
    return this.http.get('./assets/json/countrycodes.json');
  }

  openModal(groupBookModal: TemplateRef<any>) {
    this.groupBookModalRef = this.modalService.show(groupBookModal);
  }

  close() {
    this.closeModal.emit('');
  }

  receiveCountryCode(code: string) {
    this.groupBookingForms.controls['country_code'].setValue(code);
  }

  resetForm(data?: any) {
    this.groupBookingForms = this.fb.group({
      first_name: [data ? data.first_name : '', [Validators.required]],
      last_name: [data ? data.last_name : '', [Validators.required]],
      email: [data ? data.email : '', [Validators.required, Validators.email]],
      country_code: [this.countryCode ? this.countryCode : '+91', Validators.required],
      mobile_number: [data ? data.mobile_number : '', Validators.required],
      message: [data ? data.message : '', Validators.required],
      ticket_id: [this.ticketData.ticket_id ? this.ticketData.ticket_id : '', Validators.required]
    });
  }

  groupBookingSubmit(form: any) {
    if (!form.controls['email'].valid) {
      this.toastr.warning("Please enter a valid email address.");
      return; // Prevent form submission if email is invalid
    }
    if (form.valid) {
      this.isDisabled = true;
      if (!this.isEnquiry) {
        this.productService.groupBookingProduct(form.value).subscribe(
          () => {
            this.toastr.success("Group Form Submitted Successfully!");
            this.close();
            // this.resetForm();
            this.isLoading = false;
            this.isDisabled = false;
          },
          () => {
            this.toastr.warning("Something went wrong!. Try Again");
          });
      } else {
        this.productService.enquiryProduct(form.value).subscribe(
          () => {
            this.toastr.success("Enquiry Form Submitted Successfully!");
            this.close();
            // this.resetForm();
            this.isLoading = false;
            this.isDisabled = false;
          },
          () => {
            this.toastr.warning("Something went wrong!. Try Again");
          });
      }
    } else {
      this.toastr.warning("Please Fill And Submit!");
    }

  }
}
