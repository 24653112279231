<div class="trpx-experiance-tab">
  <tabset>
    <tab *ngFor="let tabz of componentData; let i=index;" [heading]="tabz?.destinationName"
      [active]="i==0 ? true : false" [customClass]="tabz.customClass"
      (selectTab)="tabClickHandler(tabz.id, tabz?.destinationName)">
      <div class="text-center">
        <img ngClass="loader_image" *ngIf="isLoading" src="./assets/images/loading-gif.gif" class="img-fluid" alt="" />
      </div>
      <div *ngIf="!isLoading">
        <trpx-activity-card [activityCards]="productDetailsData"></trpx-activity-card>
        <div class="row" *ngIf="productShowReadMore">
          <div class="col-md-12 text-center">
            <div class="readmore text-center pointer" (click)="experianceSearchParam()">
              See More
            </div>
          </div>
        </div>
      </div>
    </tab>
  </tabset>

</div>