import { Component, Input, OnInit } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';


@Component({
  selector: 'trpx-activity-card',
  templateUrl: './activity-card.component.html',
  styleUrls: ['./activity-card.component.scss']
})
export class ActivityCardComponent implements OnInit {

  @Input() activityCards: any;

  productSlug = '';
  showInfoText: { [key: string]: boolean } = {};
  // mouseX = 0;
  // mouseY = 0;

  constructor(private router: Router, private locationStrategy: LocationStrategy) {
  }
  ngOnInit(): void {
  }

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    dots: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  // @HostListener('document:mousemove', ['$event'])
  // onMouseMove(event: MouseEvent) {
  // this.mouseX = event.pageX - event.pageX;
  // this.mouseY = event.pageY - 720;
  // console.log("this.mouseXY",this.mouseX, this.mouseY)
  // }

  openLink(productSlug: string) {
    let urlTree: UrlTree = this.router.createUrlTree([`/product/${productSlug}`]);
    let url: string = this.router.serializeUrl(urlTree);

    if (this.locationStrategy instanceof HashLocationStrategy) {
      const baseHref = this.locationStrategy.getBaseHref();
      url = `${baseHref}#${url}`;
    }
    const hostUrl = window.location.origin;

    // window.open(url, '_blank');
    return `${hostUrl}${url}`;
  }
}
