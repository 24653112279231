<ngx-slick-carousel class="offer-slider carousel" #slickModal="slick-carousel" [config]="slideConfig">
    <div ngxSlickItem *ngFor="let slide of slides; let i = index" class="slide" [ngClass]="{'color1': i % 3 === 0, 'color2': i % 3 === 1, 'color3': i % 3 === 2}">
        <div class="slides" (click)="routerLink(slide)">
            <div class="slide-image"
                [ngStyle]="{background: 'url(' + slide?.image + ')' + 'no-repeat center / cover,#cccccc'}">
            </div>
            <div class="slider-content">
                <div class="heading_28">
                    {{slide.heading}}
                </div>
                <div class="description">
                    Save Up to
                </div>
                <div class="offer-percentage">
                    {{slide.subHeading}}%<span class="off-size">OFF</span>
                </div>
            </div>
        </div>
    </div>
</ngx-slick-carousel>