import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription, combineLatest, takeWhile } from 'rxjs';
import { BookingService } from 'src/app/service/booking.service';
import { HomeService } from 'src/app/service/home.service';

@Component({
  selector: 'trpx-promo-codes',
  templateUrl: './promo-codes.component.html',
  styleUrls: ['./promo-codes.component.scss'],
})
export class PromoCodesComponent implements OnInit {
  promoCodeData: any;

  selectedCountry!: string | null;
  selectedCurrency!: string | null;

  clubPass: any;

  private dataSubscription: Subscription = new Subscription();
  // @ViewChild('contentToCopy') contentToCopy!: ElementRef<HTMLDivElement>;
  @ViewChild('contentToCopy', { static: false })
  contentToCopy!: ElementRef<HTMLDivElement>;
  @ViewChild('clubPasscontentToCopy', { static: false })
  clubPasscontentToCopy!: ElementRef<HTMLDivElement>;
  constructor(
    public router: Router,
    private bookingService: BookingService,
    public homeService: HomeService,
    private toastr: ToastrService
  ) {
    this.selectedCountry = localStorage.getItem('Trip_Country') ?? '';
    this.selectedCurrency = localStorage.getItem('Trip_Currency') ?? '';

    // this.dataSubscription = this.homeService.country$.subscribe((data) => {
    //   if (data) {
    //     this.selectedCountry = data;
    //     if (this.selectedCountry && this.selectedCurrency) this.countryRelatedAPIs();
    //   }
    // });

    // this.dataSubscription = this.homeService.currency$.subscribe((data) => {
    //   if (data) {
    //     this.selectedCurrency = data;
    //     if (this.selectedCurrency && this.selectedCurrency) this.countryRelatedAPIs();
    //   }
    // });
    this.dataSubscription = combineLatest([
      this.homeService.country$,
      this.homeService.currency$,
    ])
      .pipe()
      .subscribe(([country, currency]) => {
        if (country && currency) {
          this.selectedCountry = country;
          this.selectedCurrency = currency;
          this.countryRelatedAPIs();
        }
      });
  }

  ngOnInit(): void {
    const data = localStorage.getItem('token');
    if (data) {
    } else {
      this.router.navigate([`/`]);
    }
    this.countryRelatedAPIs();
    this.getPromoCodes();
    this.getClubPassBalance();
  }

  getPromoCodes() {
    this.bookingService.getPromocode().subscribe((result) => {
      this.promoCodeData = result.results;
    });
  }
  goTolink(item: any) {
    if (item?.applicable_to_all_products == true) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['/product/', item?.product_slug]);
    }
  }

  copyContent(code: string) {
    this.copyToClipboard(code);
    // if (this.contentToCopy && this.contentToCopy.nativeElement) {
    //   const content = this.contentToCopy.nativeElement.innerText;

    //   if (content) {
    //     this.copyToClipboard(content);
    //   }
    // }
  }

  clubCopyContent() {
    if (
      this.clubPasscontentToCopy &&
      this.clubPasscontentToCopy.nativeElement
    ) {
      const content = this.clubPasscontentToCopy.nativeElement.innerText;

      if (content) {
        this.copyToClipboard(content);
      }
    }
  }

  copyToClipboard(value: string) {
    const textField = document.createElement('textarea');
    textField.innerText = value;
    document.body.appendChild(textField);
    textField.select();
    try {
      document.execCommand('copy');
      this.toastr.success('Code Copied! Ready to Use!');
    } catch (err) {
      console.error('Unable to copy', err);
      this.toastr.error('Unable to copy to clipboard');
    }
    textField.remove();
    // const textField = document.createElement('textarea');
    // textField.innerText = value;
    // document.body.appendChild(textField);
    // textField.select();
    // document.execCommand('copy');
    // textField.remove();
    // this.toastr.success('Code Copied! Ready to Use!');
  }

  countryRelatedAPIs() {}

  getClubPassBalance() {
    this.bookingService.checkClubPassBalance().subscribe((result) => {
      if (result?.total_balance) {
        this.clubPass = result;
      }
    });
  }
}
