import { Component, Input } from '@angular/core';

@Component({
  selector: 'trpx-why-choose',
  templateUrl: './why-choose.component.html',
  styleUrls: ['./why-choose.component.scss']
})
export class WhyChooseComponent {

    @Input() slides: any;
  
    constructor() {
    }

    ngOnInit(): void {}
  
    slideConfig = {
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            variableWidth: false,
            dots: true,
          },
        },
      ],
    };
  
}
