<div class="row">
    <div class="col-md-4" *ngFor="let review of reviewDetails">
        <div class="trpx-product-review-card">
            <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <div class="review-img me-3">
                        <span>{{review?.customerName!='' ? review?.customerName[0] : 'A'}}</span>
                    </div>
                    <div>
                        <div class="heading_16">{{review?.customerName!='' ? review?.customerName : 'Customer'}}</div>
                        <div class="description_14">{{ review?.reviewedAt }}</div>
                    </div>
                </div>
                <div class="d-flex align-items-center" *ngIf="review?.rating">
                    <div class="star-rating">
                        <span class="me-1" *ngFor="let _ of [].constructor(5); let i = index"
                            [class.selected]="i < review?.rating">&#9733;</span>
                    </div>
                </div>
            </div>
            <div class="description_16" [innerHTML]="review?.comment">

            </div>
            <!-- <div class="d-flex">
                <div class="sub-images" *ngFor="let items of item.subImages"
                    [ngStyle]="{background: 'url('+items.imgUrl + ')' + 'no-repeat center / cover,#cccccc'}">
                </div>
            </div> -->
        </div>
    </div>
</div>