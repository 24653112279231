import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { filterKeys } from '../model/search-results.model';


@Injectable({
    providedIn: 'root',
})


export class SearchResultService {

    constructor(private http: HttpClient) { }

    country = gql` query {
        country{
            id
            countryName
        }
    }`

    destination = gql` query destination($variable1: String!){
         destination(destination: $variable1) {
            id
            destinationName
        }
    }`

    categorySearch = gql` query categorySearch($variable1: String!){
                categorySearch(parms:$variable1)
            }`

    productDetailsInitial = gql` query productSearch($limit: Int!,$offset: Int!,$parms: String!,$currency: String!, $category: [String!]!,$rating: Int!, $sortBy: String!, $priceLte: Int!, $priceGte: Int!) {   
        productSearch(
            limit: $limit
            offset: $offset
            parms:  $parms
            currency:$currency,
            categoryList: $category,
            rating: $rating,
            sortBy: $sortBy,
            priceLte: $priceLte,
            priceGte: $priceGte
        ) {
            id
            name
            attributes
            price
            review
            rating
            imageUrl
            productSlug
            tag
            productInfo
            slashedPrice
            __typename
        }
    }`;

    productDetailsCount = gql` query searchResultsCount($variable1: String!){
                searchResultsCount(parms:$variable1)
            }`;


    getSearchId(data: any): Observable<any> {
        return this.http.post(`${environment.bookingApiUrl}/transfers_search_by_id`, data);
    }

    getAmenities() {
        return this.http.get<filterKeys[]>(`${environment.bookingApiUrl}/transfer_filter_keys`);
    }

    getAirports(query: string): Observable<any> {
        return this.http.get(`${environment.bookingApiUrl}/search_suggestion?query=${query}`);
    }

    getSearchResults(search_id: any, mode: any, value?: any): Observable<any> {
        if (value) {
            let params = new HttpParams();
            params = params.append("search_id", search_id ? search_id : "");
            params = params.append("mode", mode ? mode : "");
            params = params.append("filter_keys", value?.aminityList ? value?.aminityList : "");
            params = params.append("price_min", value?.minPrice ? value?.minPrice : "");
            params = params.append("price_max", value?.maxPrice ? value?.maxPrice : "");
            return this.http.get(`${environment.bookingApiUrl}/transfers_search_by_id`, { params: params });
        } else {
            return this.http.get(`${environment.bookingApiUrl}/transfers_search_by_id?search_id=${search_id}&mode=${mode}`);
        }

    }

    searchBoxList = gql` query product ($variable1: String!){
        product(product: $variable1){
            destination{
                destinations
                destinations_DestinationName
            }
            country{
                destinations_CountryId
                destinations_Country_CountryName
    
            }
            category{
                categories
                categories_CategoryName
    
            }
            product{
                name
                slug
    
            }
    
        }
   }`

    searchBoxSuggestion = gql` query suggestion ($variable1: String!) {
        suggestion (country: $variable1) {
            destination{
                destinationName
                id
            }
            product{
                name
                slug

            }
        }
    }`

    category = gql` query category($variable1: String!){
        category(multiday: $variable1){
            id
            categoryName
        }
    }`

    getSearchKeyword(search_type: any, search_term: any): Observable<any> {
        return this.http.get(`${environment.bookingApiUrl}/search-key-analytics?search_type=${search_type}&search_term=${search_term}`);
    }

    getExperienceLog(name: any): Observable<any> {
        return this.http.get(`${environment.bookingApiUrl}/top-destination-search-experience-log?destination_name=${name}`);
    }

    getGuidedLog(name: any): Observable<any> {
        return this.http.get(`${environment.bookingApiUrl}/top-destination-search-guided-log?destination_name=${name}`);
    }

    getTransferLog(name: any): Observable<any> {
        return this.http.get(`${environment.bookingApiUrl}/top-destination-search-transfer-log?destination_name=${name}`);
    }
}