<div class="row">
    <div class="col-md-9 px-0 px-md-2 position-relative" [class.col-md-12]="slides?.length < 4" *ngIf="slides">
        <ngx-slick-carousel id="sliderHorizontal" class="horizontal-slider carousel" #slideHorizontal="slick-carousel"
            [config]="slideConfigHorizontal" (afterChange)="afterChange($event)">
            <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                <div class="slides">
                    <div class="slide-image"
                        [ngStyle]="{background: 'url('+ slide + ')' + 'no-repeat center / cover,#cccccc'}">
                    </div>
                </div>
            </div>
        </ngx-slick-carousel>
        <div class="slick-dots div-slick-dots">
            <span *ngFor="let dotIndex of getDisplayedDots()" class="slick-dot span-slick-dot"
                [class.active]="dotIndex === activeDotIndex" (click)="goToSlide(dotIndex)">
            </span>
        </div>
    </div>
    <div class="col-md-3 position-relative d-none d-md-block" [class.d-md-none]="slides?.length < 4" *ngIf="slides">
        <ngx-slick-carousel id="sliderVertial" class="vertical-slider carousel" #slickModal="slick-carousel"
            [config]="slideConfigVertical">
            <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                <div class="slides">
                    <div class="slide-image"
                        [ngStyle]="{background: 'url('+ slide + ')' + 'no-repeat center / cover,#cccccc'}">
                    </div>
                </div>
            </div>
        </ngx-slick-carousel>
        <!-- <div class="slide-overlay" *ngIf="slides.length - 3">
            +{{ slides.length - 3}}
        </div> -->
    </div>
    <div *ngIf="slides?.length === 0">
        <div class="slide">
            <div class="image_none" [ngStyle]="{background: 'url()' + 'no-repeat center / cover,#cccccc'}">
            </div>
        </div>
    </div>
</div>