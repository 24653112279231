import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from 'src/app/service/payment.service';

@Component({
  selector: 'trpx-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
})
export class PaymentMethodsComponent implements OnInit {
  selectedPayMethod!: string;
  walletAmount: number = 0;
  walletDeduction: number = 0;
  totalPayableAmount: number = 0;
  travellerDetails: any;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public paymentService: PaymentService,
    private toastr: ToastrService
  ) {}
  ngOnInit(): void {
    const data = localStorage.getItem('token');
    if (data) {
    } else {
      this.router.navigate([`/`]);
    }
  }

  getWalletAmount() {
    this.paymentService.getWalletDetails().subscribe((response: any) => {
      this.walletAmount = response?.wallet?.amount ?? 0;
      if (this.selectedPayMethod == 'wallet') {
        if (this.totalPayableAmount > this.walletAmount) {
          this.walletDeduction = this.walletAmount;
          // this.totalPayableAmount =
          //   this.travellerDetails.total_amount - this.walletAmount;
        } else {
          // this.totalPayableAmount = this.travellerDetails.total_amount;
          this.walletDeduction = 0;
        }
      }
    });
  }

  setWalletPay() {
    if (this.selectedPayMethod == 'wallet') {
      this.getWalletAmount();
    } else {
      this.totalPayableAmount = this.travellerDetails?.total_amount;
    }
  }
}
