<div *ngIf="isSignIn && !fromPayment">
    <div #signInModal>
        <div class="trpx-login-modal-header modal-header">
            <h4 class="modal-title pull-left heading_40" *ngIf="isCustomerExist">Login or Register</h4>
            <h5 class="text-danger d-block font-17" *ngIf="!isCustomerExist">
                Hey there! You're not signed up yet. A verification code has been sent to your Whatsapp number {{mobileNumberShow}}
            </h5>
            <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="close()">
                <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button>
        </div>
        <div class="trpx-login-modal-body modal-body">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <!-- <div class="form_image_container d-flex justify-content-center"> -->
                        <div class="position-relative">
                            <div class="signin_form_outer_cont">
                                <form [formGroup]="otpGenerationForm" (ngSubmit)="generateOtp()"
                                    *ngIf="!isOtpGenerated">
                                    <div class="col-12 position-relative">
                                        <!-- <mat-form-field class="mat_select_field_country"> -->
                                        <!-- <mat-select [(value)]="selectedDial" formControlName="country_code">
                                                <mat-option *ngFor="let item of countryData" [value]="item.dial_code">
                                                    {{ item.dial_code }}
                                                </mat-option>
                                            </mat-select> -->
                                        <!-- <select matNativeControl [(ngModel)]="selectedDial" name="country"
                                                formControlName="country_code">
                                                <option *ngFor="let item of countryData" [value]="item.dial_code">
                                                    {{ item.dial_code }}
                                                </option>
                                            </select> -->
                                        <!-- </mat-form-field> -->
                                        <div class="custom_selet_component">
                                            <trpx-custom-select-box
                                                (countryCodeEvent)="receiveCountryCode($event)"></trpx-custom-select-box>
                                        </div>
                                        <mat-form-field class="mat_field_width" appearance="outline">
                                            <mat-label class="mat_field_mobile_label">WhatsApp Number</mat-label>
                                            <input type="tel" numbersOnly matInput placeholder="123-456-7890"
                                                class="mat_field_mobile_number" required
                                                formControlName="mobile_number">
                                        </mat-form-field>
                                    </div>
                                    <!-- Email input -->
                                    <!-- <div class="form-outline mb-3">
                                        <label class="form-label color_20" for="form2Example1">
                                            Mobile Number
                                        </label>
                                        <div class="d-flex align-items-center">
                                            <select class="select-code form-control" formControlName="country_code">
                                                <option *ngFor="let item of countryData" [value]="item.dial_code"
                                                    [selected]="item.dial_code === '+91'">
                                                    {{ item.dial_code }} -->
                                    <!-- &nbsp;&nbsp;&nbsp;&nbsp; -->
                                    <!-- {{ item.code }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->
                                    <!-- {{item.name}} -->
                                    <!-- </option>
                                            </select> -->
                                    <!-- <span class="text-muted mx-2"
                                                *ngIf="showPrefix || otpGenerationForm.value.mobile_number">+91</span> -->

                                    <!-- [ngClass]="{'prefix': showPrefix|| otpGenerationForm.value.mobile_number}" -->
                                    <!-- <input type="tel" placeholder="Mobile Number"
                                                class="phone_number_space form-control"
                                                formControlName="mobile_number" />
                                        </div> -->
                                    <small class="text-warning mt-1 fw-sm" *ngIf="!isRegistered">WhatsApp Number not
                                        registered! <a class="fw-bolder pointer blue_link text-warning"
                                            (click)="signUp()">Register</a> to continue.</small>
                                    <!-- </div> -->
                                    <div class="row">
                                        <div class="col-md-12 my-3 text-center">
                                            <button type="submit" class="btn btn-primary button-green w-100">
                                                <!-- <img class="me-1" *ngIf="isLoading" width="15"
                                                    src="./assets/images/loading-gif.gif" loading="lazy" alt="loader"> -->
                                                Verify
                                            </button>
                                        </div>
                                        <!-- <div class="color_20 mb-3 ">
                                                Continue without&nbsp;<u (click)="goToPage()"
                                                    class="fw-bold blue_link pointer text-decoration-none">Login</u>
                                            </div>
                                            <div class="col-md-12 d-flex justify-content-center mt-4">
                                                Don’t have an account?
                                                <span class="ps-2 pointer fw-bold blue_link" (click)="isSignIn=false;">
                                                    Sign Up
                                                </span>
                                            </div> -->
                                    </div>

                                </form>
                                <form [formGroup]="otpVariForm" (ngSubmit)="otpVerify()" *ngIf="isOtpGenerated">
                                    <div class="row justify-content-center">
                                        <!-- Email input -->
                                        <div class="col-md-12 text-center">
                                            <div class="form-outline mb-4">
                                                <div class="inputfield" formArrayName="otpFormArray">
                                                    <input type="number" maxlength="1" min="0" max="9"
                                                        class="otp_input input" required pattern="[0-9]*"
                                                        *ngFor="let input of otpInputs.controls; let i=index"
                                                        autocomplete="off" [formControlName]="i"
                                                        (input)="nextElem($event,i)" id="otpNum{{i}}"
                                                        (keydown)="disableArrowKeys($event)"
                                                        (paste)="onPaste($event)" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- 2 column grid layout for inline styling -->
                                    <div class="row">
                                        <div class="col-8 col-md-7 description_14">
                                            Check WhatsApp for your OTP!
                                        </div>
                                        <div class="col-4 col-md-5 text-end description_14" *ngIf="timeToShow!='00:00'">
                                            Resend in {{timeToShow}}
                                        </div>
                                        <div class="col-4 col-md-5 text-end description_14" *ngIf="timeToShow=='00:00'">
                                            <span (click)="resendOtp()" class="pointer color_00A651 text-decoration-underline" tabindex="-1">Resend
                                                OTP </span>
                                        </div>

                                        <div class="col-md-12 mt-4 text-center">
                                            <button type="submit" class="btn btn-primary button-green w-100"
                                                tabindex="0" id="proceed-button">
                                                <!-- <img class="me-1" *ngIf="isLoading" width="15"
                                                    src="./assets/images/loading-gif.gif" loading="lazy" alt="loader"> -->
                                                Proceed</button>
                                        </div>
                                    </div>

                                </form>
                                <div class="text-center mt-3">By signing in or creating an account you agree with our <a
                                        href="/terms-and-condition" routerLink="/terms-and-condition"
                                        (click)="close()">Terms & conditions</a> and <a href="/privacy-policy"
                                        routerLink="/privacy-policy" (click)="close()">Privacy statement</a></div>
                            </div>
                        </div>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!isSignIn && !fromPayment">
    <trpx-sign-up (signInTab)="isSignIn=true;" [showForm]="showForm" [mobileNumber]="mobileNumber"
        [countryCode]="countryCode" (closeModal)="close()" [isAgent]="isAgent"
        [isAffiliate]="isAffiliate"></trpx-sign-up>
</div>


<div class="row from-pay" *ngIf="fromPayment">
    <div class="col-12 position-relative">
        <form [formGroup]="otpGenerationForm" (ngSubmit)="generateOtp()" *ngIf="!isOtpGenerated">
            <div class="d-flex align-items-center">
                <div class="me-4 w-100">

                    <!-- <mat-form-field class="mat_select_field_country">
                        <mat-select matNativeControl [(ngModel)]="selectedDial" name="country"
                            formControlName="country_code">
                            <mat-option *ngFor="let item of countryData" [value]="item.dial_code">
                                {{ item.dial_code }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <div class="custom_selet_component">
                        <trpx-custom-select-box
                            (countryCodeEvent)="receiveCountryCode($event)"></trpx-custom-select-box>
                    </div>

                    <mat-form-field class="mat_field_width" appearance="outline">
                        <mat-label class="mat_field_mobile_label">WhatsApp Number</mat-label>
                        <input type="tel" numbersOnly matInput placeholder="123-456-7890"
                            class="mat_field_mobile_number" required formControlName="mobile_number">
                    </mat-form-field>
                </div>
                <button type="submit" class="btn btn-primary button-green mb-4">
                    <!-- <img class="me-1" *ngIf="isLoading" width="15" src="./assets/images/loading-gif.gif" loading="lazy"
                        alt="loader"> -->
                    Verify
                </button>
            </div>
            <small class="text-warning mt-1" *ngIf="!isRegistered">WhatsApp Number not
                registered! <a class="fw-bolder pointer blue_link text-warning" (click)="signUp()">Register</a> to
                continue.</small>
        </form>

        <form [formGroup]="otpVariForm" (ngSubmit)="otpVerify()" *ngIf="isOtpGenerated">
            <div class="row justify-content-center mb-4">
                <div class="col-md-12 text-center">
                    <div class="form-outline">
                        <div class="inputfield d-flex align-items-center" formArrayName="otpFormArray">
                            <input type="number" maxlength="1" min="0" max="9" class="otp_input input" required
                                pattern="[0-9]*" *ngFor="let input of otpInputs.controls; let i=index"
                                autocomplete="off" [formControlName]="i" (input)="nextElem($event,i)" id="otpNum{{i}}"
                                (keydown)="disableArrowKeys($event)" (paste)="onPaste($event)" />
                            <button type="submit" class="btn btn-primary button-green ms-3" tabindex="0"
                                id="proceed-button">
                                <!-- <img class="me-1" *ngIf="isLoading" width="15" src="./assets/images/loading-gif.gif"
                                    loading="lazy" alt="loader"> -->
                                Proceed</button>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-8 col-md-7 description_14">
                        Check WhatsApp for your OTP!
                    </div>
                    <div class="col-4 col-md-5 text-end description_14" *ngIf="timeToShow!='00:00'">
                        Resend in {{timeToShow}}
                    </div>
                    <div class="col-4 col-md-5 text-end description_14" *ngIf="timeToShow=='00:00'">
                        <span (click)="resendOtp()" class="pointer pointer color_00A651 text-decoration-underline" tabindex="-1">Resend
                            OTP </span>
                    </div>
                </div>
            </div>


        </form>

        <!-- <div *ngIf="!isSignIn && fromPayment">
            <trpx-sign-up (signInTab)="isSignIn=true;" [fromPayment]="fromPayment" [showForm]="showForm"
                [mobileNumber]="mobileNumber" [countryCode]="countryCode" (closeModal)="close()"></trpx-sign-up>
        </div> -->
    </div>

</div>

<ng-template #signup>
    <trpx-sign-up [isNew]="true" [countryCode]="countryCode" [mobileNumber]="mobileNumber" [showForm]="true"
        (closeModal)="closeSignUpModal()"></trpx-sign-up>
</ng-template>