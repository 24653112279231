import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'search-results',
    loadChildren: () => import('./search-results/search-results.module').then((m) => m.SearchResultsModule),
  },
  {
    path: 'product',
    loadChildren: () => import('./product/product.module').then((m) => m.ProductModule),
  },
  {
    path: 'purchase-success',
    loadChildren: () => import('./purchase-success/purchase.module').then((m) => m.PurchaseSuccessModule),
  },
  {
    path: 'terms-and-condition',
    loadChildren: () => import('./terms-and-condition/terms-and-condition.module').then((m) => m.TermsAndConditionModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
  },
  {
    path: 'cancellation-and-refund-policy',
    loadChildren: () => import('./cancellation-and-refund-policy/cancellation-and-refund-policy.module').then((m) => m.CancellationAndRefundPolicyModule),
  },
  {
    path: 'disclaimer',
    loadChildren: () => import('./disclaimer/disclaimer.module').then((m) => m.DisclaimerModule),
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then((m) => m.ContactUsModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.module').then((m) => m.AboutUsModule),
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then((m) => m.BlogModule),
  },
  {
    path: 'blog/specific-blog',
    loadChildren: () => import('./blog/specific-blog/specific-blog.module').then((m) => m.SpecificBlogModule),
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then((m) => m.PaymentModule),
  },
  {
    path: 'payment-failed',
    loadChildren: () => import('./payment-failed/payment-failed.module').then((m) => m.PaymentFailedModule),
  },
  {
    path: 'transfer-payment',
    loadChildren: () => import('./transfer-payment/transfer-payment.module').then((m) => m.TransferPaymentModule),
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then((m) => m.CartModule),
  },
  {
    path: 'billing-details',
    loadChildren: () => import('./billing-details/billing-details.module').then((m) => m.BillingDetailsModule),
  },
  {
    path: 'attraction-pass',
    loadChildren: () => import('./attraction-pass/attraction-pass.module').then((m) => m.AttractionPassModule),
  },
  {
    path: 'partners',
    loadChildren: () => import('./partners/partners.module').then((m) => m.PartnersModule),
  },
  {
    path: 'distributor',
    loadChildren: () => import('./distributor/distributor.module').then((m) => m.DistributorModule),
  },
  {
    path: 'dutyfree',
    loadChildren: () => import('./dummy-component/dummy-component.module').then((m) => m.DummyModule),
  },
  {
    path: 'robots.txt',
    loadChildren: () => import('./robots/robots.module').then((m) => m.RobotsModule),
  },
  {
    path: 'sitemap.xml',
    loadChildren: () => import('./sitemap/sitemap.module').then((m) => m.SitemapModule),
  },
  {
    path: ':id',
    loadChildren: () => import('./landing-page/landing-page.module').then((m) => m.LandingPageModule),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '**',
    loadChildren: () => import('./notfound-page/notfound-page.module').then((m) => m.NotfoundPageModule),
  },
  // {
  //   path: '**',
  //   redirectTo: 'home',
  //   pathMatch: 'full',
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [],
})
export class PagesRoutingModule {
  constructor() { }
}
