import { Component, Input, OnInit } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { HomeService } from 'src/app/service/home.service';
import { SearchResultService } from 'src/app/service/search-results.service';

@Component({
  selector: 'trpx-search-suggestions',
  templateUrl: './search-suggestions.component.html',
  styleUrls: ['./search-suggestions.component.scss']
})
export class SearchSuggestionsComponent implements OnInit {

  @Input() suggestionData: any;
  private dataSubscription: Subscription = new Subscription;

  searchSuggestionListData: any;
  countryId: any = "15a89f73-b053-473c-b984-0ed6ee98eb36";
  constructor(private apollo: Apollo, private router: Router, private searchService: SearchResultService,
    private homeService: HomeService, private locationStrategy: LocationStrategy) {
    this.dataSubscription = this.homeService.country$.subscribe((data) => {
      this.countryId = data;
      if (this.countryId) this.getSuggestionDetails(this.countryId);
    });
  }

  ngOnInit(): void {
    // this.getSuggestionDetails(this.countryId);
  }

  searchCountry(countryName: any) {
    // this.router.navigate([`/search-results`], { queryParams: { type: 'country', country: countryName } });
    let urlTree: UrlTree = this.router.createUrlTree(['/search-results'], { queryParams: { type: 'country', country: countryName } });
    let url: string = this.router.serializeUrl(urlTree);

    if (this.locationStrategy instanceof HashLocationStrategy) {
      const baseHref = this.locationStrategy.getBaseHref();
      url = `${baseHref}#${url}`;
    }
    const hostUrl = window.location.origin;

    // window.open(url, '_blank');
    return `${hostUrl}${url}`;
  }

  searchCategory(categoryName: any) {
    // this.router.navigate([`/search-results`], { queryParams: { type: 'category', category: categoryName } });
    let urlTree: UrlTree = this.router.createUrlTree(['/search-results'], { queryParams: { type: 'category', category: categoryName } });
    let url: string = this.router.serializeUrl(urlTree);

    if (this.locationStrategy instanceof HashLocationStrategy) {
      const baseHref = this.locationStrategy.getBaseHref();
      url = `${baseHref}#${url}`;
    }
    const hostUrl = window.location.origin;

    // window.open(url, '_blank');
    return `${hostUrl}${url}`;
  }

  searchDestination(destinationName: any) {
    // this.router.navigate([`/search-results`], { queryParams: { type: 'destination', destination: destinationName } });
    let urlTree: UrlTree = this.router.createUrlTree(['/search-results'], { queryParams: { type: 'destination', destination: destinationName } });
    let url: string = this.router.serializeUrl(urlTree);

    if (this.locationStrategy instanceof HashLocationStrategy) {
      const baseHref = this.locationStrategy.getBaseHref();
      url = `${baseHref}#${url}`;
    }
    const hostUrl = window.location.origin;

    // window.open(url, '_blank');
    return `${hostUrl}${url}`;
  }

  destinationLog(destinationName: any){
      this.searchService.getExperienceLog(destinationName).subscribe((res) => {
         let data = res;
      });
  }

  getSuggestionDetails(country: string) {
    this.apollo.watchQuery({
      query: this.searchService.searchBoxSuggestion,
      variables: {
        variable1: country,
      }
    }).valueChanges.subscribe(({ data, error }: any) => {
      this.searchSuggestionListData = data?.suggestion;
    })
  }

  openLink(productSlug: string) {
    let urlTree: UrlTree = this.router.createUrlTree([`/product/${productSlug}`]);
    let url: string = this.router.serializeUrl(urlTree);

    if (this.locationStrategy instanceof HashLocationStrategy) {
      const baseHref = this.locationStrategy.getBaseHref();
      url = `${baseHref}#${url}`;
    }
    const hostUrl = window.location.origin;

    // window.open(url, '_blank');
    return `${hostUrl}${url}`;
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
  }

}
