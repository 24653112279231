import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor() { }

  private countrySubject = new BehaviorSubject<any>(null);
  country$ = this.countrySubject.asObservable();

  private countryListSubject = new BehaviorSubject<any>([]);
  countryList$ = this.countryListSubject.asObservable();

  private currencyListSubject = new BehaviorSubject<any>([]);
  currencyList$ = this.currencyListSubject.asObservable();

  private currencySubject = new BehaviorSubject<any>(null);
  currency$ = this.currencySubject.asObservable();

  private cartCountSubject = new BehaviorSubject<any>(null);
  cartCount$ = this.cartCountSubject.asObservable();

  updateCountry(data: any) {
    localStorage.setItem('Trip_Country', data);
    this.countrySubject.next(data);
  }

  updateCountryList(data: any) {
    this.countryListSubject.next(data);
  }

  updateCurrencyList(data: any) {
    this.currencyListSubject.next(data);
  }

  updateCurrency(data: any) {
    localStorage.setItem('Trip_Currency', data);
    this.currencySubject.next(data);
  }

  updateDefaultCountry() {
    this.countryList$.subscribe((data) => { console.log(data) });
  }

  updateCartCount(data: any) {
    // localStorage.setItem('', data);
    this.cartCountSubject.next(data);
  }



  getData = gql`
    query {
      homeDescriptions {
        id
      }
    }
  `;

  homeDescriptions = gql`
    {
      homeDescriptions {
        id
        heading
        subHeading
        image
      }
    }
  `;

  homeOffer = gql`
    query {
      homeOffer {
        id
        heading
        subHeading
        image
        attractionPass{
          slug
        }
        product {
          productSlug
        }
        redirectionType
      }
    }
  `;
  homeFeature = gql`
    query {
      homeFeature {
        id
        heading
        description
        image
      }
    }
  `;
  homeCarousel = gql`
    query {
      homeCarousel {
        id
        heading
        subHeading
        image
        redirectionType
        redirectionValue
      }
    }
  `;
  testimonial = gql`
    query {
      testimonial {
        id
        image
        heading
        iconUrl
        description
        author
        stars
        isStarred
      }
    }
  `;
  usefulMedias = gql`
    query usefulMedias(
      $variable1: Int!,
      $variable2: Int!,
      $variable3: String!,
    ) {
      usefulMedias(
        limit: $variable1,
        offset: $variable2,
        mediatype: $variable3
      ) {
        id
        heading
        description
        priority
        datePublished
        image
        usefulMediaUrl
        mediaType
        author {
          id
          image
          name
          description
        }
        relatedMedias {
          id
          linkUrl
          description
          datePublished
          heading
        }
      }
    }
  `;

  productDetails = gql`
    query productDetails(
      $variable1: Int!,
      $variable2: Int!,
      $variable3: String!,
      $variable4: String!
    ) {
      productDetails(
        limit: $variable1,
        offset: $variable2,
        country: $variable3,
        currency: $variable4
      ) {
        id
        name
        attributes
        productOffers
        highlights
        price
        review
        rating
        tag
        imageUrl
        productSlug
        slashedPrice
        productInfo
      }
    }
  `;

  tabProductDetails = gql`
    query productDetails(
      $variable1: Int!,
      $variable2: Int!,
      $variable3: String!,
      $variable4: String!,
      $variable5: String!
    ) {
      productDetails(
        limit: $variable1,
        offset: $variable2,
        destination: $variable3,
        country: $variable4,
        currency: $variable5
      ) {
        id
        name
        attributes
        productOffers
        highlights
        price
        tag
        review
        rating
        imageUrl
        productSlug
      }
    }
  `;

  country = gql`
    query {
      country {
        id
        countryName
        countryCode
      }
    }
  `;

  currency = gql`
    query {
      currency {
        code
        symbol
        name
      }
    }
  `;

  destination = gql`
    query {
      destination {
        id
        imageUrl
        destinationName
      }
    }
  `;

  destinationOnCountryChange = gql`
    query destination($variable1: String!) {
      destination(country:$variable1) {
        id
        imageUrl
        destinationName
      }
    }
  `;


  catgeory = gql`
    query category($variable1: String!) {
      category(category: $variable1) {
        id
        categoryName
      }
    }
  `;
}
