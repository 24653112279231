<div class="modal-header">
  <h4 class="modal-title w-100 heading_30 text-center">Add Money</h4>
  <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">×</span>
  </button> -->
</div>
<div class="trpx_modal_wallet modal-body">
  <form>
    <mat-form-field class="mat_field_width" appearance="outline">
      <mat-label> Enter Amount</mat-label>
      <input numbersOnly matInput name="amount" type="number" placeholder="Enter Amount" required [(ngModel)]="amount">
    </mat-form-field>
  </form>
  <div class="d-flex justify-content-center align-items-center">
    <button class="btn btn-transparent rounded-pill" (click)="close()">
      Cancel
    </button>
    <button class="btn button-green rounded-pill" (click)="submitData()">
      Proceed To Add
    </button>
  </div>
</div>
<!-- <div class="modal-footer">
  <button type="button" class="btn button-green" (click)="submitData()">Proceed To Add</button>
</div> -->