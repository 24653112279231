import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss']
})
export class SitemapComponent implements OnInit {
  componentName: string = 'SitemapComponent';
  sitemapContent: string = '';

  constructor(private http: HttpClient,private sharedService: SharedService){
  }
  ngOnInit(): void {
    this.sharedService.setHideHeader(false);
    this.sharedService.setHideFooter(false);
    this.fetchSitemapXml();
  }
  fetchSitemapXml(): void {
    let url = 'https://trip-general.s3.amazonaws.com/sitemap.xml';
    this.http.get(url, { responseType: 'text' }).subscribe((res) => {
      console.log("ress", res);
      this.sitemapContent= res;
    });
  }
}
