import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private http: HttpClient) { }

  setTravellerForm(data: any): Observable<any> {
    return this.http.post(
      `${environment.bookingApiUrl}/traveller-details`,
      data
    );
  }

  getTravellerDetails(id: any, is_dynamic: any, country: any, currency?: any): Observable<any> {
    return this.http.get(
      `${environment.bookingApiUrl}/bookings?booking_id=${id}&dynamic_form=${is_dynamic}&country=${country}&currency=${currency}`
    );
  }

  downloadTicket(id: any, country: any, currency: any): Observable<Blob> {
    return this.http.get(
      `${environment.bookingApiUrl}/download-ticket?booking_id=${id}&country=${country}&currency=${currency}`,
      { responseType: 'blob' }
    );
  }

  downloadSingleTicket(id: any, country: any, currency: any): Observable<Blob> {
    return this.http.get(
      `${environment.bookingApiUrl}/download-single-ticket?booking_item=${id}&country=${country}&currency=${currency}`,
      { responseType: 'blob' }
    );
  }

  downloadZipFile(id: any, country: any, currency: any): Observable<Blob> {
    return this.http.get(
      `${environment.bookingApiUrl}/download-ticket?booking_id=${id}&country=${country}&currency=${currency}`,
      { responseType: 'blob' }
    );
  }

  transferTicketDownload(id: any, country: any, currency: any, agent_discount: any): Observable<Blob> {
    return this.http.get(
      `${environment.bookingApiUrl}/transfer-downloadticket?booking_id=${id}&country=${country}&currency=${currency}&Travel-Agent=${agent_discount}`,
      { responseType: 'blob' }
    );
  }

  postBillingDetails(data: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/billing`, data);
  }

  getWalletDetails(): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/wallet-view`);
  }

  getValueDetails(data: any) {
    return this.http.post(`${environment.bookingApiUrl}/value-design-get-balance-single`, data);
  }

  postCouponData(data: any) {
    return this.http.post(`${environment.bookingApiUrl}/redeem-coupon`, data);
  }

  getCoupenCode(id: any): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/coupon-apply?booking_id=${id}`);
  }
  getAttractionPassDetails(id: any): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/attraction-pass-name?id=${id}`);
  }

  getPriceSuggestion(data: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/accommodation-prices`, data);
  }

  // downloadTicket(id: any, country: any): Observable<Blob> {
  //     return this.http.get(`${environment.bookingApiUrl}/download-ticket?booking_id=${id}&country=${country}`, { responseType: 'blob' });
  // }

  productDetails = gql`
    query productCrosssellProducts(
      $variable1: Int!
      $variable2: Int!
      $variable3: String!
      $variable4: String!
    ) {
      productCrosssellProducts(
        limit: $variable1
        offset: $variable2
        product: $variable3
        currency: $variable4
      ) {
        id
        name
        attributes
        productOffers
        highlights
        price
        review
        rating
        imageUrl
        productSlug
      }
    }
  `;
}
