import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'trpx-activity-card-carousel',
  templateUrl: './activity-card-carousel.component.html',
  styleUrls: ['./activity-card-carousel.component.scss']
})
export class ActivityCardCarouselComponent implements OnInit {

  @Input() activityCards: any;
  constructor() {
    
  }
  ngOnInit(): void {
  }

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  
}
