<div #flightFinderModal>
    <div class="trpx-group-modal-header modal-header">
        <h4 class="modal-title pull-left heading_18">Flight Finder</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="close()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="trpx-group-modal-body modal-body">
        <div class="container">
            <div class="row">
                <div class="col-md-12 px-0">
                    <div class="position-relative">
                        <div class="group_form_outer_cont">
                            <form [formGroup]="flightFinderModalForms"
                                (ngSubmit)="flightFinderModalSubmit(flightFinderModalForms)">
                                <div class="row">
                                    <div class="col-6">
                                        <mat-form-field class="mat_date_field_width mat_date_field_fullwidth"
                                            appearance="outline"
                                            [ngClass]="{ 'border_danger': flightFinderModalForms.get('date').invalid && flightFinderModalForms.get('date').touched }">
                                            <input matInput [matDatepicker]="picker" formControlName="date"
                                                [min]="yesterday" placeholder="Select Date" value="date" readonly>
                                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-6">
                                        <mat-form-field class="mat_date_field_width"
                                            [ngClass]="{ 'border_danger': flightFinderModalForms.get('time').invalid && flightFinderModalForms.get('time').touched }">
                                            <input matInput [ngxMatTimepicker]="pickerD" placeholder="Time" readonly
                                                formControlName="time" />
                                            <mat-icon matSuffix (click)="pickerD.open()">
                                                watch_later
                                            </mat-icon>
                                        </mat-form-field>
                                        <div class="ngx-mtp-box-bordered">
                                            <ngx-mat-timepicker color="accent" appendToInput="true"
                                                #pickerD></ngx-mat-timepicker>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <mat-form-field class="mat_field_width">
                                            <input type="text" placeholder="Airline" aria-label="Airline" matInput
                                                [formControl]="airlineControl" [matAutocomplete]="auto">
                                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                <mat-option *ngFor="let item of filteredOptions | async"
                                                    [value]="item?.airline_name" (click)="selectAirlineClick(item)">
                                                    {{item?.airline_name}} ({{item?.airline_code}})
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                        <!-- <mat-form-field class="mat_field_width mat_date_field_fullwidth"
                                            appearance="outline">
                                            <mat-label>Airline</mat-label>
                                            <mat-select formControlName="airline_code" required>
                                                <mat-option *ngFor="let item of airlineNames"
                                                    [value]="item.airline_code" (click)="selectAirlineClick(item)">
                                                    {{item?.airline_name}} ({{item?.airline_code}})
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field> -->
                                    </div>
                                    <div class="col-6">
                                        <mat-form-field class="mat_field_width" appearance="outline">
                                            <mat-label>Original Airport (Optional)</mat-label>
                                            <input matInput placeholder="Airport name or IATA code..."
                                                formControlName="airport_name">
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12 text-center">
                                        <button type="submit" class="btn btn-primary button-green w-100">
                                            Find My Flight</button>
                                    </div>
                                </div>
                            </form>
                            <div class="possible_flights mt-4" *ngIf="airlineFlightNumber?.length">
                                <div class="heading_14 mb-3">
                                    Possible options
                                </div>
                                <div *ngFor="let item of airlineFlightNumber" class="flights" (click)="selectFlightItem(item)">
                                    <div class="description_14 pointer color_151515BF w-100">
                                        {{item?.airline?.airline_name}} ({{item?.airline?.airline_code}})
                                        &nbsp; {{item?.flight_number}} / &nbsp;
                                        {{item?.origin}} -> {{item?.destination}} &nbsp;/
                                        {{item?.datetime | date:'MMM dd'}} / {{item?.time}}
                                    </div>
                                </div>
                            </div>
                            <div class="possible_flights mt-4" *ngIf="airlineFlightNumber?.length == 0">
                                <div class="heading_14 mb-3">
                                    We couldn't find any flight with that number at that time.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>