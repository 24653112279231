import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { WalletService } from 'src/app/service/wallet.service';

@Component({
  selector: 'app-affiliate-withdraw-modal',
  templateUrl: './affiliate-withdraw-modal.component.html',
  styleUrls: ['./affiliate-withdraw-modal.component.scss']
})
export class AffiliateWithdrawModalComponent {

  @Input() walletDetails: any;
  withdrawForm: any = FormGroup;

  constructor(private modalService: BsModalService, private fb: FormBuilder,
    private toastr: ToastrService, private walletService: WalletService,) { }

  ngOnInit() {
    this.resetForm();
  }

  close() {
    this.modalService.hide();
  }

  withdrawRequest(form: FormGroup) {
    if (form.valid) {
      this.walletService.submitWithdrawal(form.value).subscribe((res) => {
        if (res) this.toastr.success("Request submitted!");
        this.close();
      });
    } else { this.toastr.warning("Please fill all fields."); }
  }

  resetForm() {
    this.withdrawForm = this.fb.group({
      account_number: [null, [Validators.required]],
      confirm_account_number: [null, [Validators.required]],
      ifsc_code: [null, [Validators.required]],
      amount: [null, [Validators.required]]
    }, {
      validator: this.accountMatchValidator
    });
  }

  accountMatchValidator(group: FormGroup) {
    const accno = group.controls['account_number'].value;
    const confirmAccno = group.controls['confirm_account_number'].value;
    if (accno !== confirmAccno) {
      group.controls['confirm_account_number'].setErrors({ accMismatch: true });
    } else {
      group.controls['confirm_account_number'].setErrors(null);
    }
  }
}
