export const environment = {
  production: false,
  apiUrl: 'https://dev-cms.tripxoxo.com',
  siteUrl: 'https://dev.tripxoxo.com/#/',
  contactApiUrl: 'https://dev-api.tripxoxo.com',
  bookingApiUrl: 'https://dev-booking.tripxoxo.com',
  countryUrl: 'https://stp-data-bucket.s3.ap-south-1.amazonaws.com/general/countries/',
  useHash: true,
  ipUrl: 'https://dev-booking.tripxoxo.com',
  ga4Code: 'G-TKC4G8REZP',
  gtmId: 'GTM-T8R4XNWD',
  websiteOrigin: 'https://dev.tripxoxo.com'
};
