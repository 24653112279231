import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root',
})


export class BlogService {

    constructor(private http: HttpClient) { }

    getBlogCountryList(): Observable<any> {
        return this.http.get(`${environment.bookingApiUrl}/country_list`);
    } 

    blogdescription = gql`{
        blogdescription{
            id
            heading
            description
            priority
            datePublished
            image
            usefulMediaUrl
            content
            author {
                id
                image
                name
                description
            }
        }
    }`

    newsdescription = gql`{
        newsdescription{
            id
            image
            buttonLabel
            buttonUrl
            heading
        }
    }`

    usefulMedias = gql` query usefulMedias($variable1: Int!, $variable2: Int!, $variable3: String!) {
        usefulMedias(limit: $variable1, offset:$variable2, usefulMediaUrl: $variable3){
            id
            heading
            description
            priority
            datePublished
            image
            usefulMediaUrl
            content
            mediaType
            author {
                id
                image
                name
                description
            }
            relatedMedias {
                id
                linkUrl
                description
                datePublished
                heading
            }
        }
    }`

    usefulMediasItems = gql` query usefulMedias($variable1: Int!, $variable2: Int!, $variable3: String!) {
        usefulMedias(limit: $variable1, offset:$variable2, mediatype: $variable3){
            id
            heading
            description
            priority
            datePublished
            image
            usefulMediaUrl
            content
            mediaType
            author {
                id
                image
                name
                description
            }
            relatedMedias {
                id
                linkUrl
                description
                datePublished
                heading
            }
        }
    }`

    usefulMediasCountry = gql` query usefulMedias($variable1: Int!, $variable2: Int!, $variable3: String!, $variable4: String!) {
        usefulMedias(limit: $variable1, offset:$variable2, mediatype: $variable3, country: $variable4){
            id
            heading
            description
            priority
            datePublished
            image
            usefulMediaUrl
            content
            mediaType
            createdAt
            author {
                id
                image
                name
                description
            }
            relatedMedias {
                id
                linkUrl
                description
                datePublished
                heading
            }
        }
    }`


    specificblog = gql`{
        specificblog{
            id
            heading
            image
            createdAt
            uniqueId
        }
    }`
    additionalUsefulMediaUrlParam = gql` query usefulMediaAdditional($variable: String!){
        additionalUsefulMediaUrlParam(additionalUsefulMedia:$variable){
            id
        }
    }`

    usefulmediaAdditional = gql`{
        usefulmediaAdditional{
            id
            heading
            description
            usefulMediaUrl
            createdAt
            uniqueId
            usefulMedia{
                id
            }
            image
        }
    }`

    usefulmediaAdditionalId = gql` query usefulmediaAdditional($variable: Int!){
        usefulmediaAdditional(usefulMedia:$variable){
            id
            heading
            description
            image
        }
    }`

    seoForProduct = gql` query seo($product: String!){ 
        seo(product:$product){
            id
            title
            description
            generatedSchema
        }
    }`

}