<div class="personal-info">
    <div class="row">
        <div class="col-md-12">
            <div class="heading_32">Dashboard</div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-4">
            <div class="p-4 mb-4 bg-white shadow text-center rounded">
                <h5 class="text-black fw-bold">Orders</h5>
                <h5 class="color_00A651 fw-bold mb-0">{{this.customerOrders}}</h5>
            </div>
        </div>
        <div class="col-md-4">
            <div class="p-4 mb-4 bg-white shadow text-center rounded">
                <h5 class="text-black fw-bold">Sales Amount</h5>
                <h5 class="color_00A651 fw-bold mb-0">{{this.customerSales}}</h5>
            </div>
        </div>
        <div class="col-md-4">
            <div class="p-4 mb-4 bg-white shadow text-center rounded">
                <h5 class="text-black fw-bold">Commission Amount</h5>
                <h5 class="color_00A651 fw-bold mb-0">{{this.customerCommission}}</h5>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12" *ngIf="affiliateLanding && affiliateLanding.length > 0">
            <div class="p-4 mb-4 bg-white shadow text-start rounded">
                <h5 class="text-black fw-bold mb-4">Affiliate Landing</h5>
                <table class="table table-striped overflow-hidden" style="border: 1px solid #ccc;">
                    <thead>
                        <tr class="">
                            <th class="col-md-8 text-center" colspan="2">
                                <h6 class="text-black fw-bold">Link</h6>
                            </th>
                            <th class="col-md-4 text-center" colspan="1">
                                <h6 class="text-black fw-bold">Count</h6>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="" *ngFor="let item of affiliateLanding">
                            <td class="col-md-8" colspan="2">
                                <a
                                    href="{{item?.link}}">
                                    {{item?.link}}
                                </a>
                            </td>
                            <td class="col-md-4 text-center" colspan="1">{{item?.unique_user_count}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-12" *ngIf="affiliateBooking && affiliateBooking.length > 0">
            <div class="p-4 mb-4 bg-white shadow text-start rounded">
                <h5 class="text-black fw-bold mb-4">Affiliate Booking</h5>
                <table class="table table-striped overflow-hidden" style="border: 1px solid #ccc;">
                    <thead>
                        <tr class="">
                            <th class="col-md-8 text-center" colspan="2">
                                <h6 class="text-black fw-bold">Link</h6>
                            </th>
                            <th class="col-md-4 text-center" colspan="1">
                                <h6 class="text-black fw-bold">Count</h6>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="" *ngFor="let item of affiliateBooking">
                            <td class="col-md-8" colspan="2">
                                <a
                                    href="{{item?.link}}">
                                    {{item?.link}}
                                </a>
                            </td>
                            <td class="col-md-4 text-center" colspan="1">{{item?.unique_user_count}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>