<!-- <button class="btn" (click)="markAllAsTouched()">Touch</button> -->
<form [formGroup]="form">
    <div class="row">
        <div class="col-12 form_check">
            <a *ngIf="!form.valid" class="cpr text-black-50" [tooltip]="'Click to check if this section is filled'"
                placement="right" (click)="markAllAsTouched()"><mat-icon>error_outline</mat-icon></a>
        </div>
        <div class="col-12 col-md-6 position-relative" *ngFor="let field of formData; let i=index;"
            [ngClass]="{'d-none' : field.type=='hidden' || (field.parent && !field.optional)}">
            <div class="d-none">
                <input *ngIf="field.type=='hidden'" [type]="field.type" [placeholder]="field.question"
                    [formControlName]="field.key">
            </div>

            <mat-form-field class="mat_field_width" appearance="outline" *ngIf="field.type == 'string'">
                <mat-label>{{ field.question }}</mat-label>
                <input matInput [type]="field.type" [placeholder]="field.question" [formControlName]="field.key"
                    [readonly]="field.disable" appAlphabetic>
            </mat-form-field>

            <mat-form-field class="mat_field_width" appearance="outline"
                *ngIf="['text', 'email', 'number', 'address'].includes(field.type)">
                <mat-label>{{ field.question }}</mat-label>
                <input matInput [type]="field.type" [placeholder]="field.question" [formControlName]="field.key"
                    [readonly]="field.disable">
            </mat-form-field>

            <div class="position-relative" *ngIf="field.type == 'phone'">
                <mat-form-field class="mat_field_width mobile-number" appearance="outline"
                    *ngIf="field.type == 'phone'">
                    <mat-label>{{ field.question }}</mat-label>
                    <div [formGroup]="form.get(field.key)" class="d-flex">
                        <input class="d-none" type="tel" matInput placeholder="Country Code"
                            formControlName="countryCode">
                        <input type="number" matInput placeholder="Phone Number" formControlName="phoneNumber">
                    </div>
                </mat-form-field>
                <div class="custom_selet_component dynamic">
                    <trpx-custom-select-box
                        (countryCodeEvent)="receiveCountryCode($event, field.key)"></trpx-custom-select-box>
                </div>
            </div>

            <mat-form-field class="mat_field_width mat_date_field_fullwidth" appearance="outline"
                *ngIf="field.type == 'date'">
                <mat-label>{{ field.question }}</mat-label>
                <input matInput [matDatepicker]="picker" [formControlName]="field.key" [min]="field.min"
                    [max]="field.max" changeDateFormat>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="mat_field_width" appearance="outline" *ngIf="field.type == 'textarea'">
                <mat-label>{{ field.question }}</mat-label>
                <textarea matInput [placeholder]="field.question" [formControlName]="field.key" [rows]="1"></textarea>
            </mat-form-field>

            <mat-form-field class="mat_field_width mat_date_field_fullwidth date-time-pick" appearance="outline"
                *ngIf="field.type == 'datetime'">
                <mat-label>{{ field.question }}</mat-label>
                <input matInput [type]="field.type" [placeholder]="field.question" [formControlName]="field.key">
                <!-- <input matInput type="datetime-local" [formControlName]="field.key" [min]="today"> -->
                <input class="d-none" matInput [matDatepicker]="picker" [min]="field.min" [max]="field.max"
                    changeDateFormat (dateInput)="onDateChange($event, field)">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker (closed)="Dpicker?.open()"></mat-datepicker>
                <div class="d-none">
                    <input matInput [ngxMatTimepicker]="Dpicker">
                    <ngx-mat-timepicker appendToInput="true" #Dpicker
                        (timeSet)="timeChanged($event, field)"></ngx-mat-timepicker>
                </div>
            </mat-form-field>

            <mat-form-field class="mat_field_width mat_date_field_fullwidth" appearance="outline"
                *ngIf="['select', 'list'].includes(field.type)">
                <mat-label>{{ field.question }}</mat-label>
                <mat-select [formControlName]="field.key" (selectionChange)="checkChildren()">
                    <mat-option *ngFor="let item of field.list" [value]="item">
                        {{item}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="mat_field_width mat_date_field_fullwidth number_unit" appearance="outline"
                *ngIf="field.type == 'number_unit'">
                <mat-label>{{ field.question }}</mat-label>
                <div [formGroup]="form.get(field.key)" class="d-flex">
                    <div class="d-flex w-100">
                        <input type="number" matInput [placeholder]="field.question" formControlName="numeric"
                            class="unit-numeric">
                        <mat-select formControlName="unit" class="w-25">
                            <mat-option *ngFor="let item of field.list" [value]="item">
                                {{item}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </mat-form-field>

            <mat-form-field class="mat_field_width mat_date_field_fullwidth" appearance="outline"
                *ngIf="field.type=='suggestion'">
                <mat-label>{{ field.question }}</mat-label>
                <input type="text" [placeholder]="field.question" matInput [formControlName]="field.key"
                    [matAutocomplete]="auto" (input)="filterComplete(form.value[field.key], field.list)">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let item of field.list;" [value]="item">{{item}}</mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field class="mat_field_width mat_date_field_fullwidth" appearance="outline"
                *ngIf="['list_label_suggestion'].includes(field.type)">
                <mat-label>{{ field.question }}</mat-label>
                <input type="text" [placeholder]="field.question" matInput [formControlName]="field.key"
                    [matAutocomplete]="auto" (input)="filterLabelComplete(form.value[field.key], field.list)">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let item of field.list;" [value]="item.label">{{item.label}}</mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field class="mat_field_width mat_date_field_fullwidth" appearance="outline"
                *ngIf="['list_with_label'].includes(field.type)">
                <mat-label>{{ field.question }}</mat-label>
                <mat-select [formControlName]="field.key">
                    <mat-option *ngFor="let item of field.list" [value]="item.value">
                        {{item?.label}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="mat_field_width mat_date_field_fullwidth" appearance="outline"
                *ngIf="['list multiple'].includes(field.type)">
                <mat-label>{{ field.question }}</mat-label>
                <mat-select [formControlName]="field.key" multiple>
                    <mat-option *ngFor="let item of field.list" [value]="item">
                        {{item}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="mat_field_width mat_date_field_fullwidth" appearance="outline"
                *ngIf="field.type == 'time'">
                <mat-label>{{ field.question }}</mat-label>
                <input matInput [ngxMatTimepicker]="picker" [formControlName]="field.key" readonly>
                <ngx-mat-timepicker #picker></ngx-mat-timepicker>
            </mat-form-field>

            <mat-form-field class="mat_field_width" appearance="outline" *ngIf="field.type == 'file'">
                <mat-label>{{ field.question }}</mat-label>
                <input matInput [type]="'url'" [placeholder]="field.question" [formControlName]="field.key">
                <input type="file" id="fileUpload{{i}}{{index}}" (change)="getFile($event, field.key)"
                    [accept]="field.accept" style="display:none" />
                <button class="btn" type="button" mat-icon-button matSuffix
                    (click)="triggerClick('fileUpload'+i+index);">
                    <mat-icon>cloud_upload</mat-icon>
                </button>
                <mat-progress-bar *ngIf="progress" mode="determinate" [value]="progress"></mat-progress-bar>
            </mat-form-field>

            <mat-form-field class="mat_field_width" appearance="outline" *ngIf="field.type == 'pnr'">
                <mat-label>{{ field.question }}</mat-label>
                <input matInput [type]="field.type" [placeholder]="field.question" [formControlName]="field.key"
                    [readonly]="field.disable" (input)="upperCaseFn($event, field)" (focus)="showPNRHint = true"
                    (blur)="showPNRHint = false" autocomplete="off">
                <mat-error
                    *ngIf="form.get(field.key).invalid && (form.get(field.key).touched || form.get(field.key).dirty)">
                    Enter a valid 6 digit IndiGo PNR
                </mat-error>
            </mat-form-field>


            <mat-checkbox *ngIf="field.type == 'boolean'" [formControlName]="field.key">
                {{ field.question }}
            </mat-checkbox>
        </div>
    </div>
</form>