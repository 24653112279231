<ngx-slick-carousel class="destination-slider carousel d-md-block d-none" #slickModal="slick-carousel"
    [config]="slideConfig">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
        <a href="{{searchDestination(slide?.destinationName)}}">
            <div class="slides">
                <div class="slide-image" [ngStyle]="{
  'background': slide?.imageUrl ? 'url(' + slide.imageUrl + ') no-repeat center / cover' : 'url(assets/images/destination-2.webp) no-repeat center / cover',
  'background-color': slide?.imageUrl ? 'transparent' : '#cccccc'
}">
                </div>
                <div class="slider-content">
                    <div class="heading_20">
                        {{slide?.destinationName}}
                    </div>
                </div>
            </div>
        </a>
    </div>
</ngx-slick-carousel>

<div class="destination-slider carousel d-block d-md-none">
    <div class="container">
        <div class="row">
            <div *ngFor="let slide of slides" class="col-6">
                <div class="slide">
                    <a href="{{searchDestination(slide?.destinationName)}}">
                        <div class="slides">
                            <div class="slide-image" [ngStyle]="{
                            'background': slide?.imageUrl ? 'url(' + slide.imageUrl + ') no-repeat center / cover' : 'url(assets/images/destination-2.webp) no-repeat center / cover',
                            'background-color': slide?.imageUrl ? 'transparent' : '#cccccc'
                          }">
                            </div>
                            <div class="slider-content">
                                <div class="heading_20">
                                    {{slide?.destinationName}}
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>