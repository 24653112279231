import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

@Component({
  selector: 'trpx-vertical-carousel',
  templateUrl: './vertical-carousel.component.html',
  styleUrls: ['./vertical-carousel.component.scss'],
})
export class VerticalCarouselComponent implements OnInit {
  @Input() slides: any;
  @ViewChild('slideHorizontal') slideHorizontal!: SlickCarouselComponent;
  constructor() {
  }
  ngOnInit(): void {
  }

  slideConfigHorizontal = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: false,
    arrows: false,
    loop: true,
    autoplay: true,
    autoplaySpeed: 2000,
    variableWidth: false,
    fade: true,
    cssEase: 'linear',
    // asNavFor: '#sliderVertial',
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };

  slideConfigVertical = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    loop: true,
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    variableWidth: false,
    vertical: true,
    initialSlide: 1,
    cssEase: 'linear',
    // verticalSwiping: true,
    // asNavFor: '#sliderHorizontal',
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };
  activeDotIndex: number = 0;
  dotsToShow: number = 10;
  afterChange(e: any) {
    this.activeDotIndex =
      e.currentSlide % (this.slides.length > 10 ? 10 : this.slides.length);
  }
  getDisplayedDots(): number[] {
    // Determine the range of dots to display
    const totalSlides = this.slides.length;
    const dotsCount = Math.min(totalSlides, this.dotsToShow);
    const startIndex = Math.max(
      0,
      this.activeDotIndex - Math.floor(this.dotsToShow)
    );

    let displayedDots: number[] = [];

    for (let i = 0; i < dotsCount; i++) {
      displayedDots.push((startIndex + i) % totalSlides);
    }

    return displayedDots;
  }
  goToSlide(index: number) {
    this.activeDotIndex = index;
    this.slideHorizontal.slickGoTo(index);
  }
}
