import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'trpx-offers-card',
  templateUrl: './offers-card.component.html',
  styleUrls: ['./offers-card.component.scss']
})
export class OffersCardComponent implements OnInit {
  @Input() slides: any;
  @Input() componentName: any;

  constructor(public router: Router,) {

  }
  ngOnInit(): void { }

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
          dots: true,
        },
      },
    ],
  };

  routerLink(slide: any) {
    if (slide?.redirectionType === 'PRODUCT-PAGE') {
      const productSlug = slide.product?.productSlug;
      this.router.navigate(['/product', productSlug]);
    }
    if (slide?.redirectionType === 'ATTRACTION-PASS-PAGE') {
      const attractionSlug = slide?.attractionPass?.slug;
      this.router.navigate(['/attraction-pass', attractionSlug]);
    }
  }

}
