import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { DashboardService } from 'src/app/service/dashboard.service';

@Component({
  selector: 'trpx-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  affiliate_params_id: any;
  currentAffiliateUrl: any;
  fromDate: any;
  toDate: any;
  customerSales: any;
  customerOrders: any;
  customerCommission: any;
  affiliateLanding: any[] = [];
  affiliateBooking: any[] = [];


  constructor(
    private http: HttpClient,
    public cdr: ChangeDetectorRef,
    public router: Router,
    public dashboardService: DashboardService,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.route.queryParams.subscribe((params) => {
      this.affiliate_params_id = params['affiliate_id'];
      this.currentAffiliateUrl = window.location.origin + this.router.url;
    });
    const today = new Date();
    const last30DaysDate = new Date(today);
    last30DaysDate.setDate(today.getDate() - 30);

    // Store formatted dates
    this.toDate = this.datePipe.transform(today, 'yyyy-MM-dd');
    this.fromDate = this.datePipe.transform(last30DaysDate, 'yyyy-MM-dd');
  }
  ngOnInit(): void {
    const data = localStorage.getItem('token');
    if (data) {
    } else {
      this.router.navigate([`/`]);
    }
    this.getAffiliateLinkLanding();
    this.getAffiliateLinkBooking();
    this.getCustomerSale(this.fromDate, this.toDate);
    this.getCustomerOrders(this.fromDate, this.toDate);
    this.getTotalCommission(this.fromDate, this.toDate);
  }

  getCustomerSale(fromDate: any, toDate: any) {
    this.dashboardService
      .getCustomerSale(fromDate, toDate)
      .subscribe((result) => {
        this.customerSales = result?.total_sales_amount;
      });
  }

  getCustomerOrders(fromDate: any, toDate: any) {
    this.dashboardService
      .getCustomerOrders(fromDate, toDate)
      .subscribe((result) => {
        this.customerOrders = result?.total_orders;
      });
  }

  getTotalCommission(fromDate: any, toDate: any) {
    this.dashboardService
      .getTotalCommission(fromDate, toDate)
      .subscribe((result) => {
        this.customerCommission = result?.total_commission_amount;
      });
  }

  getAffiliateLinkLanding() {
    this.dashboardService.getAffiliateLinkLanding().subscribe((result) => {
      console.log('getAffiliateLinkLanding', result);
      this.affiliateLanding = result;
    });
  }
  
  getAffiliateLinkBooking() {
    this.dashboardService.getAffiliateLinkBooking().subscribe((result) => {
      console.log('getAffiliateLinkBooking', result);
      this.affiliateBooking = result;
    });
  }
}
