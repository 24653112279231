import { Component, Input } from '@angular/core';

@Component({
  selector: 'trpx-attraction-slider',
  templateUrl: './attraction-slider.component.html',
  styleUrls: ['./attraction-slider.component.scss']
})
export class AttractionSliderComponent {
  @Input() slides: any;
  @Input() componentName: any;


  constructor() {
 
  }
  ngOnInit(): void { }

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };
}
