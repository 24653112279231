import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { Apollo } from 'apollo-angular';
import { LandingService } from 'src/app/service/landing.service';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'trpx-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  headerData: any;
  componentName: string = 'LandingComponent';
  landingCardsList: any;
  landingCardsGrid: any;
  pageName: any;
  firstLoading: boolean = true;
  dataNull: any;
  pageNameHeading: any;
  loaderGif: boolean = false;

  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    public landService: LandingService,
    private route: ActivatedRoute,
    public router: Router,
    private sharedService: SharedService,
    private locationStrategy: LocationStrategy
  ) { }
  ngOnInit(): void {
    let baseUrl = window.location.origin;
    if (baseUrl.includes('indigo')) {
      this.loaderGif = true;
    }
    // Accessing route parameters
    this.pageName = this.route.snapshot.params['id'];
    if (this.pageName) {
      this.getLandingDetails();
    }
    this.sharedService.setShowHeader(false);
  }

  getLandingDetails() {
    this.apollo
      .watchQuery({
        query: this.landService.landingPage,
        variables: {
          landingSlug: this.pageName,
          fields: [
            'id',
            'name',
            'price',
            'tag',
            'product_slug',
            'product_description_set',
            'product_banners',
            'product_icons',
            'vendor__slug',
            'slashed_price',
          ],
        },
      })
      .valueChanges.subscribe(({ data, error }: any) => {
        this.dataNull = data?.landingPage;
        if (this.dataNull && this.dataNull.length === 0) {
          this.router.navigate(['/']);
        }
        let result = data?.landingPage?.page_type;
        this.headerData = data?.landingPage?.HEADER;
        if (result == 'GRID-LANDING-PAGE') {
          this.landingCardsGrid = data?.landingPage?.PRODUCTS;
          this.firstLoading = false;
        }
        if (result == 'LIST-LANDING-PAGE') {
          this.landingCardsList = data?.landingPage?.PRODUCTS;
          this.firstLoading = false;
        }
        this.pageNameHeading = this.headerData?.subheading2;
      });
  }

  openLink(productSlug: string) {
    let urlTree: UrlTree = this.router.createUrlTree([`/product/${productSlug}`]);
    let url: string = this.router.serializeUrl(urlTree);

    if (this.locationStrategy instanceof HashLocationStrategy) {
      const baseHref = this.locationStrategy.getBaseHref();
      url = `${baseHref}#${url}`;
    }
    
    const hostUrl = window.location.origin;

    // window.open(url, '_blank');
    return `${hostUrl}${url}`;
  }
}
