import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitaliseAndReplaceUnderscore'
})
export class CapitaliseAndReplaceUnderscorePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';
    return value.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
  }

}
