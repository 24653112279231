import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  headers: any;

  private customerSubject = new BehaviorSubject<any>(null);
  customers$ = this.customerSubject.asObservable();

  private loginPopupSubject = new BehaviorSubject<any>(null);
  isLoginShown$ = this.loginPopupSubject.asObservable();

  private mobileSubject = new BehaviorSubject<any>(false);
  mobile$ = this.mobileSubject.asObservable();

  constructor(private http: HttpClient, public toastr: ToastrService) { }

  ngOnInit(): void {


    // 'Authorization': 'Token f26b64798d5c9581d026c2c2d296861274784f41'
  }

  updateMobile(data: any) {
    this.mobileSubject.next(data);
  }

  updateCustomer(data: any) {
    this.customerSubject.next(data);
  }

  updateLoginPopShown(status: boolean) {
    this.loginPopupSubject.next(status);
  }

  getHeader() {
    let token = localStorage.getItem('token');
    if (token != null && token != undefined && token != '') {
      token = JSON.parse(token);

      this.headers = new HttpHeaders({
        'Content-Type': 'application/json',

        // Authorization: `Token ${token}`
        Authorization: `Token ${token}`
      });
    }
  }


  isLoggedIn(): boolean {
    let token = localStorage.getItem('token');

    if (token != null && token != undefined && token != '') {

      return true;
    } else {

      return false;
    }
  }


  isRefreshToken(): boolean {
    let refreshtime = localStorage.getItem('refreshtime');
    let refreshtoken = localStorage.getItem('refreshtoken');
    if (refreshtime && refreshtoken) return true;
    return false;
  }

  createUser(data: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/customer-registration`, data);
  }

  createAgent(data: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/travel-agent-registration`, data);
  }

  loginOtpGeneration(data: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/send-otp`, data);
  }

  loginOtpForVerify(data: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/verify-otp`, data);
  }

  updateCustomerProfile(id: any, data: any): Observable<any> {
    return this.http.patch(`${environment.bookingApiUrl}/customer/${id}`, data);
  }

  updateAgentCustomerProfile(id: any, data: any): Observable<any> {
    return this.http.patch(`${environment.bookingApiUrl}/travel-agent/${id}`, data);
  }

  getTokenFromRefresh(data: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/token-refresh`, data);
  }

  checkIfTokenValid(token: string | null) {
    return this.http.get(`${environment.bookingApiUrl}/token-validation?token=${token}`);
  }

  getCustomerProfile(): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/customer`);
  }

  getAgentCustomerProfile(): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/travel-agent`);
  }

  deleteCustomerProfile(data?: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/customer`, data);
  }

  getToken() {
    const token = localStorage.getItem('token');
    return token && token != 'undefined' ? JSON.parse(token) : null;
  }

  getCustomer() {
    const customer = localStorage.getItem('customer_profile') ?? null;
    if (customer && JSON.parse(customer) && Object.keys(JSON.parse(customer)).length === 0) {
      return null;
    }
    return customer ? JSON.parse(customer) : null;
  }

  setToken(token: any) {
    localStorage.setItem('token', JSON.stringify(token));
  }

  setLogo(logo: string) {
    localStorage.setItem('Logo', logo);
  }

  getLogo() {
    return localStorage.getItem('Logo') || null;
  }


  setRefreshToken(token: any) {
    localStorage.setItem('refreshtime', JSON.stringify(new Date().getTime()));
    localStorage.setItem('refreshtoken', token);
  }

  setCustomer(customer_profile: any) {
    if (customer_profile)
      localStorage.setItem('customer_profile', JSON.stringify(customer_profile));
  }

  setIfCustomerExists(exists: any) {
    localStorage.setItem('is_customer_exists', JSON.stringify(exists));
  }

  setCustomerMobile(customer_mobile: any) {
    localStorage.setItem('customer_mobile', JSON.stringify(customer_mobile));
  }

  getItemFromLS(item: any) {
    const itemValue = localStorage.getItem(item);
    return itemValue && itemValue != 'undefined' ? JSON.parse(itemValue) : null;
  }

  checkIfCustomerExists() {
    const exists = localStorage.getItem('is_customer_exists');
    return exists != null && exists != 'undefined' ? JSON.parse(exists) : null;
  }

  checkIfCMCustomerExists() {
    const exists = localStorage.getItem('customer_profile');
    if (!exists || exists === 'undefined') {
      return false;
    }

    const customerData = JSON.parse(exists);
    if (customerData?.source === "CLUB-MAHINDRA") {
      return true;
    }

    return false;
  }

  public logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('customer_profile');
    localStorage.removeItem('Trip_Travel_Agent');
    localStorage.removeItem('white_label_source');
  }

  getCountries(name: string): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/country?name=${name}`);
  }

  clubmahindraValidation(requestBody: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/cm-validation`, requestBody);
  }

  whiteLabelValidation(): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/white-labels`);
  }

  check404URL(url: string): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/url-redirect?sourceurl=${url}`);
  }

  setSource(source: any) {
    localStorage.setItem('white_label_source', source);
  }

}
