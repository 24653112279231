import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root',
})


export class CartWishListService {

    constructor(private http: HttpClient) { }

    getWishListDetails(country: any, currency: any): Observable<any> {
        return this.http.get(`${environment.bookingApiUrl}/wishlist-view?country=${country}&currency=${currency}`);
    }

    postWishListDetails(data: any): Observable<any> {
        return this.http.post(`${environment.bookingApiUrl}/wishlist-view`, data);
    }

    getCartDetails(country: any, currency: any): Observable<any> {
        return this.http.get(`${environment.bookingApiUrl}/cart-view?country=${country}&currency=${currency}`);
    }

    updateCartDetails(id: any, data: any): Observable<any> {
        return this.http.put(`${environment.bookingApiUrl}/cart-view/${id}`, data);
    }

    postCartDetails(data: any): Observable<any> {
        return this.http.post(`${environment.bookingApiUrl}/cart-view`, data);
    }

    removeCartDetails(id: any): Observable<any> {
        return this.http.delete(`${environment.bookingApiUrl}/cart-view/${id}`);
    }

    removeWishList(data: any): Observable<any> {
        return this.http.post(`${environment.bookingApiUrl}/remove-wishlist`, data);
    }

}