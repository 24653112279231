import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

@Component({
  selector: 'trpx-destination-card',
  templateUrl: './destination-card.component.html',
  styleUrls: ['./destination-card.component.scss'],
})
export class DestinationCardComponent implements OnInit {
  @Input() slides: any;

  constructor(private router: Router,private locationStrategy: LocationStrategy) {
    
  }
  ngOnInit(): void {}

  slideConfig = {
    slidesToShow: 6,
    slidesToScroll: 1,
    infinite: false,
    loop: true,
    dots: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  searchDestination(destinationName: any) {
    // this.router.navigate([`/search-results`], { queryParams: { type: 'destination', destination: destinationName } });
    let urlTree: UrlTree = this.router.createUrlTree(['/search-results'], { queryParams: { type: 'destination', destination: destinationName } });
    let url: string = this.router.serializeUrl(urlTree);

    if (this.locationStrategy instanceof HashLocationStrategy) {
      const baseHref = this.locationStrategy.getBaseHref();
      url = `${baseHref}#${url}`;
    }
    const hostUrl = window.location.origin;

    // window.open(url, '_blank');
    return `${hostUrl}${url}`;
  }


}
