import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { checkout, checkoutResponse } from '../model/transfer-payment.model';

@Injectable({
  providedIn: 'root'
})
export class TransferService {

  constructor(private http: HttpClient) { }

  getTransferBookingDetails(search_id: string, result_id: string, mode: boolean, country_id: string, currency: string): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/transfers_booking_details?search_id=${search_id}&result_id=${result_id}&mode=${mode}&country_id=${country_id}&currency=${currency}`);
  }

  getAirlineRetrieve(): Observable<any> {
    return this.http.get(`${environment.bookingApiUrl}/airline_retrieve`);
  } 
  
  postAirlineRetrieveFlight(data: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/airline_retrieve_flightno`, data);
  }
  
  postAirlineRetrieveFlightVerify(data: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/airline_retrieve_flightno_verify`, data);
  }

  postCheckoutData(data:checkout){
    let params = new HttpParams();
    const dataTopass={
      total_price:parseInt(data?.total_price),
      transfers_details_id:data?.transfers_details_id,
      special_instruction: data?.special_instruction,
      airline: data?.airline,
      flight_no: data?.flight_no,
      return_airline: data?.return_airline,
      return_flight_number: data?.return_flight_number,
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      phone_number: data?.phone_number,
      country_code: data?.country_code,
    }
    params = params.append("country_id", data?.country_id ?? "").append( "currency", data?.currency ?? "");
    return this.http.post<checkoutResponse>(`${environment.bookingApiUrl}/transfers_booking`,dataTopass,{params:params})
  }

}
