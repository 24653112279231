import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'trpx-robots',
  templateUrl: './robots.component.html',
  styleUrls: ['./robots.component.scss']
})
export class RobotsComponent implements OnInit{
  componentName: string = 'RobotsComponent';
  robotsContent: string = '';

  constructor(private http: HttpClient,private sharedService: SharedService){

  }

  ngOnInit(): void {
    this.sharedService.setHideHeader(false);
    this.sharedService.setHideFooter(false);
    this.fetchRobotsTxt();
  }
  fetchRobotsTxt(): void {
    let url = 'https://trip-general.s3.amazonaws.com/robots.txt';
    this.http.get(url, { responseType: 'text' }).subscribe((res) => {
      this.robotsContent= res;
    });
  }
}
