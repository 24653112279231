<ngx-slick-carousel class="why-choose-slider carousel" #slickModal="slick-carousel" [config]="slideConfig">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
        <div class="slides">
            <div class="trpx-choose-content">
                <img src="{{ slide.image }}" class="img-fluid" alt="">
                <div class="heading_18">{{slide.heading}}</div>
                <div [title]="slide.description" class="description">
                    {{slide.description.length<205 ? slide.description : slide.description.slice(0, 200) + '...' }}
                        </div>
                </div>
            </div>
        </div>
</ngx-slick-carousel>