import {
  ChangeDetectorRef,
  Component,
  Output,
  TemplateRef,
  EventEmitter,
  Input,
  ViewChild
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/service/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { CartWishListService } from 'src/app/service/cart-wishlist-service';

@Component({
  selector: 'trpx-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent {
  signInModalRef?: BsModalRef;

  otpGenerationForm: any = FormGroup;
  otpVariForm: any = FormGroup;

  userActivity: any;
  timeToShow: any;
  timeIntervalSet: any;
  isOtpGenerated: boolean = false;
  isRegistered: boolean = true;
  showPrefix = false;
  passedPhone: any;
  isLoading: boolean = false;
  isSignIn = true;
  countryData: any;
  showForm = false;
  mobileNumber = null;
  mobileNumberShow: any;
  countryCode = null;
  @Input() isAffiliate: any;
  @Input() isAgent: any;
  @Input() fromPayment: any;
  @Output() setCustomerForm = new EventEmitter<any>();
  @Output('closeModal') closeModal: EventEmitter<any> = new EventEmitter();
  modalRef?: BsModalRef;
  @ViewChild('signup')
  signup!: TemplateRef<any>;

  // userInactive: Subject<any> = new Subject();
  selectedDial: string = '+91';
  toCartData: any;

  constructor(
    private http: HttpClient,
    public router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public authService: AuthService,
    private modalService: BsModalService,
    private cdr: ChangeDetectorRef,
    public cartWishService: CartWishListService,
  ) {
    this.passedPhone = this.router
      ?.getCurrentNavigation()
      ?.extras?.state?.['mobile_number'].toString();

    this.otpGenerationForm = this.fb.group({
      country_code: ['+91', Validators.required],
      mobile_number: [
        this.passedPhone ? this.passedPhone : null,
        Validators.required,
      ],
    });

    this.otpVariForm = this.fb.group({
      otpFormArray: this.fb.array([
        this.fb.control(''),
        this.fb.control(''),
        this.fb.control(''),
        this.fb.control(''),
      ]),
    });

    this.getData().subscribe((response: any) => {
      this.countryData = response;
      this.cdr.detectChanges();
    });

    //Start:- Idle logout is here Dont delete
    // this.userInactive.subscribe((res) => {

    // });
    //End:- Idle logout is here Dont delete
  }

  ngOnInit(): void {
    this.popShowSubject();
  }

  popShowSubject() {
    this.authService.isLoginShown$.subscribe((res) => {
      if (res) {
        this.isSignIn = res;
      }
    });
  }

  disableArrowKeys(event: KeyboardEvent) {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  }

  getData() {
    return this.http.get('./assets/json/countrycodes.json');
  }

  receiveCountryCode(code: string) {
    this.otpGenerationForm.controls['country_code'].setValue(code);
  }

  openModal(signInModal: TemplateRef<any>) {
    this.signInModalRef = this.modalService.show(signInModal, {
      class: 'modal-dialog-centered',
      animated: true,
    });
  }

  close() {
    this.closeModal.emit('');
  }

  get otpInputs(): FormArray {
    return this.otpVariForm.get('otpFormArray') as FormArray;
  }

  nextElem(event: any, i: number) {
    let next = event.target.nextElementSibling;
    let val = event.target.value;

    if (next != null && val.length == 1) {
      if (val) next.focus();
    } else {
      const proceedButton = document.querySelector('#proceed-button');
      (proceedButton as HTMLElement).focus();
    }
  }

  timer(minute: any) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.timeToShow = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        clearInterval(timer);
      }
    }, 1000);

    return timer;
  }

  isCustomerExist: boolean = true;

  generateOtp() {
    this.isLoading = true;
    if (this.otpGenerationForm.invalid) {
      this.otpGenerationForm.markAllAsTouched();
    }

    if (this.isRegistered) {
      let dataTosend = this.otpGenerationForm.value;
      dataTosend.mobile_number = dataTosend.mobile_number.toString().replace(/\s/g, "");
      this.mobileNumberShow = dataTosend.mobile_number;

      this.authService.loginOtpGeneration(dataTosend).subscribe(
        (res: any) => {
          // if (!this.isAffiliate && !this.isAgent) {

          if (res?.is_travel_agent_exists) {
            this.isAgent = true;
            if (!res?.is_travel_agent_approved) {
              this.toastr.warning('Your travel agent account is not approved by admin!');
              this.close();
              return;
            }
          }
          if (!this.isAgent) {
            if (!res.is_customer_exists) {
              this.isCustomerExist = false;
            }
          } else if (this.isAgent) {
            this.isCustomerExist = res.is_travel_agent_exists;
          }

          if (this.timeToShow == null || this.timeToShow == undefined) {
            // this.timeIntervalSet = this.timer(res.interval_time);
            this.timeIntervalSet = this.timer(3);
          } else {
            clearInterval(this.timeIntervalSet);
            // this.timeIntervalSet = this.timer(res.interval_time);
            this.timeIntervalSet = this.timer(3);
          }
          this.isOtpGenerated = true;
          this.isLoading = false;
          setTimeout(() => {
            const proceedButton = document.querySelector('#otpNum0');
            (proceedButton as HTMLElement).focus();
          }, 500);
        },
        () => {
          this.isLoading = false;
        }
      );
    } else {
      this.toastr.warning('Please register to continue!');
    }
  }

  resendOtp() {
    clearInterval(this.timeIntervalSet);
    this.otpVariForm.reset();
    this.generateOtp();
  }

  logIntoHome() {
    this.router.navigate(['/home']);
  }

  otpVerify() {
    this.isLoading = true;

    let country_code = this.otpGenerationForm.value.country_code;
    let mobile_number = this.otpGenerationForm.value.mobile_number;

    let otp: any = this.otpInputs.value.join('');
    let dataTosend = {
      country_code: country_code,
      mobile_number: mobile_number,
      otp: otp,
      is_affiliate: this.isAffiliate ?? false,
      is_agent: this.isAgent ?? false
    };
    if (otp.length) {
      this.authService.loginOtpForVerify(dataTosend).subscribe(
        (res: any) => {
          this.isLoading = false;

          const setCustomer = (exists: boolean) => {
            this.authService.setIfCustomerExists(exists);
            if (exists) {
              this.authService.setToken(res?.token?.access);
              this.authService.setRefreshToken(res?.token?.refresh);
              this.authService.setCustomer(res?.customer_profile);
              this.authService.updateCustomer(res?.customer_profile);
              let currentURL = this.router.url;
              this.router.navigateByUrl('/', { skipLocationChange: true })
                .then(() => this.router.navigateByUrl(currentURL));
              // if (res.is_travel_agent_exists) {
              //   window.location.reload();
              // }
              this.toastr.success('Logged In Successfully!');
              if (sessionStorage.getItem('toCart')) {
                let cartData: string | null = sessionStorage.getItem('toCart');
                this.toCartData = cartData;
                try {
                  const parsedCartData = JSON.parse(this.toCartData);

                  // Pass the parsed data to the API
                  this.cartWishService.postCartDetails(parsedCartData).subscribe(
                    (res) => {
                      this.toastr.success('Product Added To Cart!');
                      // Remove the item from sessionStorage after successful operation
                      sessionStorage.removeItem('toCart');
                    },
                    (error) => {
                      console.error("Error adding product to cart:", error);
                    }
                  );
                } catch (error) {
                  console.error("Error parsing JSON data from sessionStorage:", error);
                }
              }
            } else {
              // this.authService.setCustomer({
              //   country_code: country_code,
              //   mobile_number: mobile_number,
              // });
            }
          };

          if (!this.isAgent) {
            setCustomer(res.is_customer_exists);
          } else {
            setCustomer(res.is_travel_agent_exists);
            localStorage.setItem('Trip_Travel_Agent', JSON.stringify(res?.travel_agent_secret));
          }


          // if(currentURL.includes('product/') && res?.is_customer_exists){
          //   this.router
          //   .navigateByUrl('/', { skipLocationChange: true })
          //   .then(() => this.router.navigate(['/payment'])); 
          // }else{

          // }
          if (!this.fromPayment) {
            // let currentURL = this.router.url.substr(1);
            // let url_lists = ['product/', '/payment', 'transfer-payment'];
            // if (!url_lists.some(url => currentURL.includes(url))) {
            // this.router
            // .navigateByUrl('/', { skipLocationChange: true })
            // .then(() => this.router.navigate([currentURL]));
            // }
            // let currentURL = this.router.url;

            // Navigate to the same URL with query parameters
            // this.router.navigateByUrl('/', { skipLocationChange: true })
            //   .then(() => this.router.navigateByUrl(currentURL));
          } else {
            this.setCustomerForm.emit(this.isCustomerExist);
          }

          // let currentURL = this.router.url;
          // this.router.navigateByUrl('/', { skipLocationChange: true })
          //   .then(() => this.router.navigateByUrl(currentURL));

          // if (!this.fromPayment) {this.setCustomerForm.emit(this.isCustomerExist);}

          if (this.isCustomerExist) {
            this.close();
          } else {
            this.showForm = true;
            this.mobileNumber = mobile_number;
            this.countryCode = country_code;
            this.isSignIn = false;
            if (this.fromPayment) {
              // let currentURL = this.router.url;
              // this.router.navigateByUrl('/', { skipLocationChange: true })
              //   .then(() => this.router.navigateByUrl(currentURL));
              // this.openSignupModal(this.signup);
              this.authService.setCustomerMobile({
                "country_code": this.countryCode,
                "mobile_number": this.mobileNumber,
              });
              this.authService.updateMobile({
                "country_code": this.countryCode,
                "mobile_number": this.mobileNumber,
              });
            }
          }
        },
        (err: any) => {
          this.isLoading = false;
          this.otpVariForm.reset();
          this.toastr.error(err?.error?.message ?? err?.message ?? 'Not a Valid OTP!')
        }
      );
    } else {
      this.toastr.warning('Enter Valid OTP!');
    }

  }
  goToPage() {
    this.close();
    this.router.navigate(['/home']);
  }

  signUp() {
    if (this.otpGenerationForm.value.mobile_number) {
      const signUpModalRef = this.modalService.show(SignUpComponent);
    } else {
      const signUpModalRef = this.modalService.show(SignUpComponent);
    }
  }

  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');
    this.otpVariForm = this.fb.group({
      otpFormArray: this.fb.array([
        this.fb.control(pastedText[0]),
        this.fb.control(pastedText[1]),
        this.fb.control(pastedText[2]),
        this.fb.control(pastedText[3]),
      ]),
    });
    event.preventDefault();
  }

  openSignupModal(template: any) {
    const modalConfig = {
      ignoreBackdropClick: true
    };

    const combinedConfig = {
      ...modalConfig,
      class: 'modal-dialog-centered',
      animated: true,
    };

    this.modalRef = this.modalService.show(template, combinedConfig);
  }

  closeSignUpModal() {
    this.modalRef?.hide();
  }

}
