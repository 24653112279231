<div class="trpx-experiance-cards d-none d-md-block">
    <div class="row">
        <div class="col-md-3" *ngFor="let card of activityCards">
            <div class="trpx-experiance-card">
                <div class="trpx-experiance-image"
                    [ngStyle]="{background: 'url('+card?.image_url + ')' + 'no-repeat center / cover,#cccccc'}">
                    <div class="starred-div heading_16" *ngIf="card?.starred">{{card?.starred}}</div>
                </div>
                <div class="trpx-experiance-content">
                    <div class="d-flex justify-content-between">
                        <div class="heading_20">{{card?.heading}}</div>
                        <div class="trpx-experiance-star-rating">
                            <span>
                                <img src="./assets/icons/Star.svg" class="img-star" alt="">
                            </span>
                            <span class="heading_20">{{card?.rating}}</span>
                        </div>
                    </div>
                    <div class="mb-1">From</div>
                    <div class="prices heading_20">{{card?.new_price}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="trpx-experiance-cards d-md-none d-block">
    <div class="row">
        <div class="col-md-12 pe-0">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
                (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
                (beforeChange)="beforeChange($event)">
                <div ngxSlickItem *ngFor="let card of activityCards" class="slide">
                    <div class="trpx-experiance-card">
                        <div class="trpx-experiance-image"
                            [ngStyle]="{background: 'url('+card?.image_url + ')' + 'no-repeat center / cover,#cccccc'}">
                            <div class="starred-div heading_16" *ngIf="card?.starred">{{card?.starred}}</div>
                        </div>
                        <div class="trpx-experiance-content">
                            <div class="d-flex justify-content-between">
                                <div class="heading_20">{{card?.heading}}</div>
                                <div class="trpx-experiance-star-rating">
                                    <span>
                                        <img src="./assets/icons/Star.svg" class="img-star" alt=""></span>
                                    <span class="heading_20">{{card?.rating}}</span>
                                </div>
                            </div>
                            <div class="mb-1">From</div>
                            <div class="prices heading_20">{{card?.new_price}}</div>
                        </div>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</div>