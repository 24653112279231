<div #groupBookModal>
    <div class="trpx-group-modal-header modal-header">
        <h4 class="modal-title pull-left heading_18">{{!isEnquiry ? 'Group' : ''}} Booking Enquiry</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="close()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="trpx-group-modal-body modal-body">
        <div class="container">
            <div class="row">
                <div class="col-md-12 px-0">
                    <div class="position-relative">
                        <div class="group_form_outer_cont">
                            <form [formGroup]="groupBookingForms" (ngSubmit)="groupBookingSubmit(groupBookingForms)">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-form-field class="mat_field_width" appearance="outline">
                                            <mat-label>First Name</mat-label>
                                            <input matInput appAlphabetic placeholder="Enter Your First Name" required
                                                formControlName="first_name" maxlength="20"
                                                [ngModel]="formData?.first_name">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12">
                                        <mat-form-field class="mat_field_width" appearance="outline">
                                            <mat-label>Last Name</mat-label>
                                            <input matInput appAlphabetic placeholder="Enter Your Last Name" required
                                                formControlName="last_name" maxlength="20"
                                                [ngModel]="formData?.last_name">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12">
                                        <mat-form-field class="mat_field_width" appearance="outline">
                                            <mat-label>Email</mat-label>
                                            <input type="email" matInput placeholder="Enter your email ID" required
                                                formControlName="email" [ngModel]="formData?.email">
                                        </mat-form-field>
                                        <mat-error *ngIf="groupBookingForms.get('email').hasError('email') && groupBookingForms.submitted">
                                            Please enter a valid email address.
                                        </mat-error>
                                    </div>
                                    <div class="col-12 position-relative">
                                        <div class="custom_selet_component">
                                            <trpx-custom-select-box (countryCodeEvent)="receiveCountryCode($event)"></trpx-custom-select-box>
                                        </div>
                                        <mat-form-field class="mat_field_width" appearance="outline">
                                            <mat-label class="mat_field_mobile_label">Mobile Number</mat-label>
                                            <input type="tel" matInput appNumeric placeholder="123-456-7890"
                                                class="mat_field_mobile_number" required formControlName="mobile_number"
                                                [ngModel]="formData?.mobile_number">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12">
                                        <mat-form-field class="mat_field_width" appearance="outline">
                                            <mat-label>Message</mat-label>
                                            <input matInput placeholder="Enter Meassage" required
                                                formControlName="message"  maxlength="500">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 text-center">
                                        <button type="submit" class="btn btn-primary button-green w-100"
                                            [disabled]="isDisabled">
                                                Enquire Now</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>