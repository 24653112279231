import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
    selector: '[appAlphabetic]'
})
export class AlphabeticDirective {
    constructor(private el: ElementRef) { }
    @HostListener('input', ['$event']) onInput(event: Event): void {
        const inputElement = this.el.nativeElement as HTMLInputElement;
        const inputValue: string = inputElement.value;
        // Remove non-alphabetic characters
        const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
        inputElement.value = alphabeticValue;
    }
}